.job-offer {
    &-meta {
        &,
        .btn {
            margin-bottom: 8rem;

            @include media('<=sm') {
                margin-bottom: 75px;
            }
        }

        p {
            font-size: 2.8rem;

            @include media('<=lg') {
                font-size: 18px;
            }

            @include media('<=sm') {
                font-size: 18px;
            }
            @include media('<xs') {
                font-size: 15px;
            }
        }
    }

    &-benefits {
        margin: 3.2rem 0 13rem;
        padding: 13.5rem 0 9rem;
        position: relative;
        color: $color-white;
        text-align: center;

        @include media('<=sm') {
            margin: 70px 0;
            padding: 80px 0;
        }

        .background {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: no-repeat 50% 0 / cover;
            z-index: -1;
        }

        .title {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 9.5rem;
            line-height: 1;
            font-family: $font-heading;
            font-weight: bold;
            letter-spacing: (-2 / 95) * 1em;
            @include reset-letter-spacing(9.5);

            @include media('<=sm') {
                font-size: 42px;
                margin-bottom: 12px;
            }
            @include media('<xs') {
                font-size: 34px;
            }
        }
    }

    &-benefit-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 6.3rem;
        font-size: 2.4rem;

        @include media('<=lg') {
            font-size: 16px;
        }
        @include media('<=sm') {
            margin-top: 50px;
            font-size: 18px;
        }
        @include media('<xs') {
            font-size: 15px;
        }

        .benefit {
            flex: 0 0 (4 / 24 * 100%);
            padding: 7.2rem 1.3rem;

            @include media('<=md') {
                flex: 0 0 25%;
                padding: 25px 15px;
            }

            @include media('<=sm') {
                flex: 1 0 50%;
                padding: 40px 15px;
            }

            @include media('<xs') {
                padding: 20px 15px;
            }
        }

        .icon {
            width: 25%;
        }

        .name {
            margin-top: 3.2rem;
            line-height: 1.25;

            @include media('<=sm') {
                margin-top: 20px;
            }
        }
    }
}

.job-list-empty {
    @include media('<=sm') {
        margin-bottom: 43px;
    }
}
