.carrier-list{
    margin-bottom: 80px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;


    @include media('>=lg') {
        padding-left: 0;
    }
    @include media('<md') {
        margin-bottom: 8vh;
        margin-top: 8vh;
    }
    @include media('>=md', '<lg') {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    &__content{

        ul{
            li{
                font-size: 2.8rem;
                list-style: square;
                padding-left: 24px;
                margin-bottom: 24px;

                @include media('<=md') {
                    font-size: 16px;
                }

                @include media('>md' , '<lg') {
                    font-size: 16px;
                }
            }
        }
    }
}
.paragraph--type--carrier-job-offers{
    display: flex;

    h2{
        font-size: 4.8rem;

        @include media('<md') {
            font-size: 28px;
        }
    }

    @include media('<md') {
        flex-direction: column;
    }
}


