.carrier-icons{
    margin-bottom: 15vh;

    @include media('>=lg') {
        padding-left: 0;
    }

    @include media('<=md') {
        margin-bottom: 8vh;
    }
    @include media('>=md', '<lg') {
        margin-bottom: 40px;
    }
    &__icon{
        display: flex;
    }
    &__content{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__text{
        p{
            font-size: 2rem;
            color: $color-gray;
            width: 70%;

            @include media('<md') {
                font-size: 11px;
                width: 90%;
            }

            @include media('<=md') {
                font-size: 12px;
            }

            @include media('>md' , '<lg') {
                font-size: 13px;
            }
        }
    }

    &__headline{
        margin-bottom: 4rem;
    }

    &__item{
        width: 23%;
        margin-bottom: 2rem;

        @include media('<md') {
            width: 30%;
            margin-bottom: 6rem;
        }
        img{
            width: 80px;
            height: 80px;
            margin-bottom: 2rem;
            object-fit: cover;

            @include media('<md') {
               width: 40px;
               height: 40px;
            }

        }
    }
}
