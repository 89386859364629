$top-desktop: 2.5rem;
$top-tablet: 3rem;
$top-phone: 20px;
$top-xs-phone: 20px;

.top-bar-wrap {
    position: absolute;
    top: 5.6rem;
    width: 100%;

    @include media('<=sm') {
        top: 10px;
    }

    /*.path-frontpage & {
        position: fixed;
        top: 0;
        z-index: 1;
        pointer-events: none;
    }*/
}

.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    //width: 100%;
    padding: $top-desktop 0;
    z-index: 10;
    color: $color-black;
    transition: 0.5s transform, 0.5s padding, 0.2s color;
    transform: translateY(0%);
    //background: $color-white;

    /*.path-frontpage & {
        pointer-events: none;
    }*/

    @include media('<=md') {
        padding: $top-tablet 0;
    }
    @include media('<=sm') {
        padding: $top-phone 0;
        height: 33px + 2*$top-phone;
    }
    @include media('<xs') {
        padding: $top-xs-phone 0;
        height: 26px + 2*$top-xs-phone;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color-black;
        transition: 0.4s opacity, 0.5s transform;
        top: -5.6rem;
        opacity: 0;

        @include media('<=sm') {
            top: -10px;
        }
    }

    .dark-header & {
        color: $color-white;

        .logo-svg {
            filter: invert(1);
        }

        .top-bar-title-white {
            opacity: 1;
            pointer-events: all;
        }

        .top-bar-title-black {
            opacity: 0;
            pointer-events: none;
        }

        .btn {
            border-color: $color-white;
            @include btn-colors($color-white, transparent, $color-black, $color-white);
        }
    }

    @include media('>sm') {
        .sm-dark-header & {
            color: $color-white;

            .top-bar-title-white {
                opacity: 1;
                pointer-events: all;
            }

            .top-bar-title-black {
                opacity: 0;
                pointer-events: none;
            }

            .btn {
                border-color: $color-white;
                @include btn-colors($color-white, transparent, $color-black, $color-white);
            }
        }
    }

    &.headroom--not-top {
        position: fixed;
        color: $color-white;

        .logo-svg {
            filter: invert(1);
        }

        &::before {
            transform: translateY(0);
            opacity: 1;
            transition: 0.2s opacity, 0.2s transform;
        }

        .btn {
            border-color: $color-white;
            @include btn-colors($color-white, transparent, $color-black, $color-white);
        }
    }

    &.headroom--top {
        //background: none;
        transform: translateY(0%) !important;
        transition-property: padding;

        &::before {
            transform: translateY(-20rem);
            opacity: 0;

            @include media('<=sm') {
                transform: translateY(-85px);
            }
        }
    }

    @include media('<=sm') {
        &.headroom--unpinned {
            transform: translateY(-105%);
        }

        &.headroom--pinned {
            transform: translateY(0%);
        }
    }

    &.no-animation {
        transition-property: none !important;
    }

    /*.path-frontpage & {
        padding: ($top-desktop + 5.6rem) 0 0;

        @include media('<=md', 'height>420px') {
            padding: ($top-tablet + 5.6rem) 0 0;
        }
        @include media('<=sm') {
            //padding: ($top-phone + 10px) 0;
            padding: $top-phone 0;
        }
        @include media('<xs') {
            padding: $top-xs-phone 0;
        }

        @include media('>sm') {
            //background: none;
            &::before {
                transform: translateY(-17rem);
                opacity: 0;
            }

            &.semi-inverted {
                color: $color-mine-shaft-gray;

                .right-menu .btn {
                    border-color: $color-mine-shaft-gray;
                    @include btn-colors($color-mine-shaft-gray, transparent, $color-mine-shaft-gray, $color-white);
                }
            }

            &.inverted {
                color: $color-white;

                .top-bar-title-white {
                    opacity: 1;
                    pointer-events: all;
                }

                .top-bar-title-black {
                    opacity: 0;
                    pointer-events: none;
                }

                .right-menu .btn {
                    color: $color-white;
                    border-color: $color-white;

                    @include btn-colors($color-white, transparent, $color-black, $color-white);
                }
            }
        }

        &.top {
            //background: none;
            &::before {
                transform: translateY(-17rem);
                opacity: 0;
            }
        }
    }*/

    &-content-wrap {
        width: (24 / 28) * 100%;
        margin: 0 auto;
    }

    .page-title {
        display: inline-block;
        min-height: 5.3rem;
        font-size: 3.6rem;
        line-height: (53 / 36);
        letter-spacing: -0.033em;
        font-weight: bold;
        font-family: $font-heading;
        vertical-align: middle;
        pointer-events: all;
        @include reset-letter-spacing(3.6);
        //transition: 0.5s color;

        @include media('<=very-lg') {
            font-size: 4.5rem;
            line-height: 8rem;
        }

        @include media('<=md') {
            visibility: hidden;
            height: 33px;
        }
        @include media('<xs') {
            font-size: 27px;
            height: 27px;
        }
    }

    &-title {
        //transition: 0.5s opacity;

        &-white {
            color: $color-white;
            opacity: 0;
            position: absolute;
            left: 0;
            pointer-events: none;
        }

        &-black {
            color: $color-black;
            opacity: 1;
        }
    }

    .inverted & {
        color: $color-white;

        .top-bar-title-white {
            opacity: 1;
            pointer-events: all;
        }

        .top-bar-title-black {
            opacity: 0;
            pointer-events: none;
        }

        .btn {
            border-color: $color-white;
            @include btn-colors($color-white, transparent, $color-black, $color-white);
        }
    }

    .btn {
        @include media('<md') {
            display: none;
        }
    }
}

.logo-wrapper {
    width: 230px;
    position: fixed;
    left: $top-xs-phone;
    top: 4.5em;

    @include media('>=sm') {
        top: 8rem;
    }
    @include media('>=md') {
        width: 155px;
        left: $top-tablet;
        top: $top-tablet+0.9rem;
    }
    @include media('>=lg') {
        width: 330px;
        left: $top-desktop;
        top: $top-desktop+1rem;
    }
    @include media('>=very-lg') {
        top: 3rem;
    }
    @include media('>=xl') {
        top: 2.5rem;
    }

}
.logo-link {
    display: block;
    height: auto;
    width: 230px;
}
.logo-svg {
    width: 230px;
    @include media('>=md') {
        width: 155px;
    }
    @include media('>=lg') {
        width: 195px;
    }
    @include media('>=xl') {
        width:330px;
    }
}

.top-bar,
.mega-menu {
    .right-menu {
        position: absolute;
        z-index: 11;
        //transition: 0.5s;
        //transition-property: color, top;
        top: $top-desktop;
        display: flex;
        align-items: center;
        pointer-events: all;

        @include media('<=md') {
            top: $top-tablet;
        }
        @include media('<=sm') {
            top: $top-phone;
        }
        @include media('<xs') {
            top: $top-xs-phone;
        }

    }

    .right-menu {
        display: flex;
        align-items: center;
        float: right;
        font-size: 10px;
        right: (2 / 28) * 100%;
        min-height: 5.3rem;

        @include media('<=very-lg') {
            min-height: 8rem;
        }

        @include media('>=sm') {
            margin-right: -3em;
        }

        @include media('<=very-lg') {
            font-size: 9px;
        }

        @include media('>fullhd') {
            font-size: 1rem;
        }

        @include media('<sm') {
            font-size: 8px;
            right: 30px;
            min-height: 33px;
        }

        @include media('<xs') {
            min-height: (33/1.23)*1px;
        }
    }

    .lang-menu {
        //float: right;
        list-style: none;
        margin: 0 4rem 0 0;
        padding: 0;
        font-size: 1.8em;
        font-weight: bold;
        font-family: $font-heading;

        @include media('<sm') {
            margin-right: 23px;
            font-size: 18px;
        }

        li {
            display: inline-block;
        }

        a {
            display: inline-block;
            padding: 0 0.8em;
            color: inherit;
            text-decoration: none;
            line-height: 30px;
        }
    }

    .btn {
        margin: 0 4rem 0 0;

        @include media('<sm') {
            width: auto;
            margin: 0 auto;
        }
    }
}

.mega-menu {
    .right-menu {
        margin-top: 5.6rem;

        @include media('<=md', 'landscape') {
            margin-top: 0;
        }
    }
}

/*.path-frontpage {
    .top-bar {
        .logo,
        .right-menu {
            top: $top-desktop + 5.6rem;
            transition: 0.5s;
            transition-property: color, top;

            @include media('<=md', 'height>420px') {
                top: $top-tablet + 5.6rem;
            }
            @include media('<=sm') {
                //top: $top-phone + 10px;
                top: $top-phone;
            }
            @include media('<xs') {
                top: $top-xs-phone;
            }
        }
    }

    .page-title {
        position: relative;
        transition: 0.5s 0.4s opacity, 0.5s color;
    }

    &.main-slider-active,
    .top-bar-on-footer {
        .page-title {
            opacity: 0;
            pointer-events: none;
            transition: 0.3s opacity, 0.5s color;
        }
    }

    .top-bar-title {
        transition: 0.5s opacity;
    }
}*/

.inline-nav {
    display: inline-flex;
    align-items: center;
    min-height: 5.3rem;

    @include media('<=very-lg') {
        min-height: 8rem;
    }

    @include media('<sm') {
        display: none;
    }

    .secondary-nav {
        display: inline-block;
        margin: 0;
        padding: 0;
        font-family: $font-heading;
        font-weight: bold;
        font-size: 2.4rem;

        @include media('<=very-lg') {
            font-size: 3.5rem;
        }

        @include media('<=md') {
            font-size: 16px;
        }

        li {
            display: inline-block;
            margin-left: 1.8rem;
        }
    }
}
