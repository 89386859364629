.article-body-text {
    //font-size: 3rem;
    font-size: 2.8rem;
    line-height: 1.66;
    margin-bottom: 9.6rem;

    @include media('>1440px') {
        font-size: 21px;
    }
    @include media('>1600px') {
        font-size: 2.5rem;
    }
    @include media('<=very-lg') {
        font-size: 20px;
    }
    @include media('<=lg') {
        font-size: 18px;
    }
    @include media('<=sm') {
        font-size: 18px;
    }
    @include media('<xs') {
        font-size: 15px;
    }

    h2 {
        margin: 2em 0 1.1em;
        font-family: $font-heading;
        font-weight: bold;
        font-size: 4.8rem;
        line-height: 1.15;
        letter-spacing: (-0.23 / 48) * 1em;
        @include reset-letter-spacing(4.8);

        @include media('<=lg') {
            font-size: 28px;
        }
        @include media('<=sm') {
            font-size: 28px;
            margin: 64px 0 23px;
        }
        @include media('<xs') {
            font-size: 22px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        margin: 0;
        font-family: $font-regular;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.66;

        @include media('<=lg') {
            font-size: 18px;
        }
        @include media('<=sm') {
            font-size: 18px;
        }
        @include media('<xs') {
            font-size: 15px;
        }
    }

    p,
    ul,
    li,
    blockquote {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    p {
        line-height: 1.66;
    }

    .big-text {
        //margin: 9rem (-1 / 6) * 100%;
        margin: 12.2rem (-1 / 14) * 100%;
        font-size: (4.4 / 2.8) * 1em;
        line-height: 1.39;
        letter-spacing: -0.025em;

        @include media('<=lg') {
            font-size: 28px;
            line-height: 1.36;
        }
        @include media('<=sm') {
            margin: 71px 0 61px;
            font-size: 28px;
            line-height: 1.36;
        }
        @include media('<xs') {
            font-size: 22px;
        }
    }

    blockquote {
        position: relative;
        margin: 9.3rem 0 10.5rem;
        padding-left: (1 / 14) * 100%;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0.5rem;
            left: 0;
            width: (1 / 14) * 100%;
            height: 100%;
            padding-right: 2rem;
            background: url('../img/layout/quote.png') no-repeat 0 0 / 66% auto;
        }

        p {
            margin-bottom: 0.5em;
            line-height: 1.68;
        }
    }

    cite {
        display: block;
        font-size: (1.8 / 2.8) * 1em;
        font-family: $font-heading;
        font-style: normal;
        font-weight: bold;
        line-height: 1;
        margin-top: 1.7em;

        @include media('<=lg') {
            font-size: 12px;
        }
    }

    /*em {
        font-size: 4.8rem;
        font-family: $font-heading;
        font-style: normal;
        font-weight: bold;
        line-height: 1;
        letter-spacing: (-0.23 / 48) * 1em;
        @include reset-letter-spacing(4.8);

        @include media('<=sm') {
            font-size: 28px;
        }
    }*/

    ul {
        margin: 4.8rem 0 6.5rem;
        padding-left: 4.5rem;
        list-style: none;

        @include media('<=md') {
            //padding-left: 2.5rem;
        }
        @include media('<=sm') {
            margin: 0 0 1.4em;
            padding-left: 20px;
        }

        li {
            position: relative;
            margin-bottom: 1.7em;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0.7em;
                left: -20px;
                width: 6px;
                height: 6px;
                margin: 0 15px 0 0;
                vertical-align: middle;
                background: $color-black;
                //border-radius: 50%;

                @include media('>sm') {
                    width: 0.3em;
                    height: 0.3em;
                    left: -4.3rem;
                }
            }
        }

        &.condensed li {
            margin-bottom: 0;
        }
    }

    .summary-list {
        display: flex;
        flex-wrap: wrap;
        margin: 9.6rem 0;
        padding: 0;
        line-height: 1.2;
        list-style: none;

        &:first-child {
            margin-top: 0;
        }

        li {
            width: 50%;
            margin-bottom: 4.6rem;

            @include media('<=md') {
                width: 100%;
            }

            &::before {
                display: none;
            }
        }

        strong {
            display: block;
            margin-bottom: -0.8rem;
            font-family: $font-heading;
            font-weight: bold;
            font-size: (12 / 2.8) * 1em;
            letter-spacing: -0.025em;

            @include media('<=lg') {
                font-size: 28px;
                margin-bottom: 0;
            }
        }
    }
}

.article-video {
    margin: 13.5rem (4 / 28) * 100%;

    @include media('<=sm') {
        margin: 71px 0;
    }
}

.article-image {
    margin: 13.5rem (4 / 28) * 100% 12rem;
    text-align: center;

    &--wide {
        @include media('>sm') {
            margin: 16.5rem (2 / 28) * 100%;
        }
    }

    @include media('<=sm') {
        margin: 71px 30px 67px;

        &.mobile-fullbleed {
            margin-right: 0 !important;
            margin-left: 0 !important;

            figcaption {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    picture {
        display: inline-block;
    }

    figcaption {
        padding: 0.9em 0;
        text-align: center;
        font-family: $font-heading;
        font-weight: bold;

        @include media('<=lg') {
            font-size: 14px;
        }

        @include media('<=sm') {
            font-size: 14px;
        }
        @include media('<xs') {
            font-size: (14 / 1.23) * 1px;
        }
    }
}

.article-meta {
    margin-top: 4.7rem;
    font-size: 2.4rem;

    @include media('<=lg') {
        font-size: 18px;
    }

    @include media('<=sm') {
        font-size: 18px;
    }
    @include media('<xs') {
        font-size: 15px;
    }
}

.article-author {
    margin: 15rem 0 14rem;

    @include media('<=sm') {
        margin: 73px 0;
    }

    .author-info {
        line-height: 1.5;

        &:not(.no-image) {
            @include media('>sm') {
                padding-left: 3rem;
            }
        }

        .name {
            margin-bottom: 0.3em;
            font-size: 3.6rem;
            line-height: 1.3;
            font-family: $font-heading;
            font-weight: bold;

            @include media('<=lg') {
                font-size: 22px;
            }

            @include media('<=sm') {
                font-size: 22px;
            }
            @include media('<xs') {
                font-size: 18px;
            }
        }
    }

    @include media('<=sm') {
        .author-image {
            margin-bottom: 34px;
        }

        .author-info {
            margin-bottom: 28px;
        }
    }

    @include media('>sm') {
        .author-info,
        .author-image {
            margin-bottom: 5rem;
        }
    }
}
