.tippy {
    &-tooltip {
        padding: 6rem;
        border-radius: 0;
        background: $color-black;
        font-size: 1.6rem;
        line-height: 1.5;
        text-align: left;
    }

    &-content {
        .tooltip-close {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            color: $color-white;
            background: none;
            border: 2rem solid transparent;
            outline: 0;
            cursor: pointer;
            appearance: normal;
            z-index: 1;
            font-size: 0.55rem;

            @include media('<=very-lg') {
                font-size: 5.5px;
            }

            span {
                display: block;
                width: 3em;
                height: 3em;
                transition: 0.15s transform ease-in-out;

                &::before,
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 0;
                    right: 0;
                    top: 50%;
                    width: 100%;
                    border-bottom: 3px solid;
                    transform-origin: 50% 50%;

                    @include media('>fullhd') {
                        border-bottom-width: 4px;
                    }
                }

                &::before {
                    transform: translateY(-2px) rotate(45deg);
                }

                &::after {
                    transform: translateY(-2px) rotate(-45deg);
                }
            }

            &:hover span {
                transform: rotate(90deg);
            }
        }
        .tooltip-text {
            a {
                text-decoration: underline;
            }
        }
    }
}
