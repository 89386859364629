.header-contact {

    @include media('>md') {
        height: 100vh;
        max-height: 70vw;
    }
    @include media('<sm') {
         min-height: 400px;
        padding-top: 93px;
     }


    .contact-form-trigger {
        display: inline-block;
        margin-top: 1em;
    }

    h1 {
        font-size: 13rem;
        color: $color-white;
        @include media('<=lg') {
            font-size: 38px;
        }

    }

    .subtitle {
        font-size: 3.6rem;
        margin-top: 16rem;
        color: $color-white;

        @include media('<=lg') {
            font-size: 24px;
            line-height: 1.44;
        }
        @include media('<=sm') {
            margin-top: 40px;

        }
    }
}

button.contact-form-trigger {
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
    color: inherit;

    &:focus,
    &:active {
        outline: none;
    }
}

.contact {
    &__person-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16rem;
        margin-bottom: 16rem;
        @include media('<md') {
            display: block;
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }
    &__person {
        flex: 0 1 auto;
        min-width: 25%;

        @include media('>md') {
            flex: 0 1 25%;
            margin-right: 5%;

        }
        @include media('<lg') {
            flex: 0 1 30%;
            margin-right: 3%;
        }
        &:last-child {
            margin-right: 0;
        }
        @include media('<md') {
            margin-bottom: 60px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .title {
            margin-top: 3.5rem;
            line-height: 1.25;
            @include media('<=lg') {
                margin: 22px 0 14px;
                font-size: 24px;
            }
            @include media('<=sm') {
                margin: 22px 0 14px;
            }
        }

        p {
            font-size: 2.4rem;
            margin-bottom: 0;
            line-height: 1.56;
            @include media('<=very-lg') {
                font-size: 16px;
            }

        }
    }


    &__address {
        background-color: $color-black;
        color: $color-white;
        padding-top: 16rem;
        padding-bottom: 16rem;
        font-size: 2.4rem;
        @include media('<sm') {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        &-head {
            .h3 {
                margin-bottom: 0.67em;
                font-size: 9rem;
                &:first-child {
                    margin-top: 0;
                }
                @include media('<=lg') {
                    font-size: 36px;
                }
            }
            .subtitle-office {
                font-size: 3.6rem;
                margin-bottom: 1.2em;
                font-weight: 800;
                @include media('<=lg') {
                    font-size: 24px;
                }
            }
        }
        p {
            @include media('<=lg') {
                font-size: 16px;
            }
        }
        dl {
            margin: 0 0 1.4em;
            line-height: 1.5;

            @include media('<=lg') {
                font-size: 18px;
                line-height: 1.7;
            }

            @include media('<xs') {
                font-size: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        dt {
            float: left;
            width: 2em;
        }

        dd {
            margin: 0;
            padding-left: 2.2em;
        }

        .side-links {
            &,
            ul {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    &-form {
        &-wrap {
            margin-top: 12rem;
            margin-bottom: 14rem;

            @include media('<=sm') {
                margin-top: 40px;
                margin-bottom: 90px;
            }
        }

        &-title {
            margin-top: 2.8rem;

            @include media('<=md') {
                margin-top: 0;
                font-size: 24px;
            }
        }

        &-inline {
            .form-title {
                margin-bottom: 3.3rem;

                @include media('<=sm') {
                    margin-top: 32px;
                    margin-bottom: 33px;
                }

                h2 {
                    font-size: 7rem;
                    line-height: 1.11;
                    letter-spacing: (-1.75/70)*1em;

                    @include media('<=lg') {
                        font-size: 35px;
                    }
                    @include media('<=sm') {
                        font-size: 36px;
                    }
                    @include media('<xs') {
                        font-size: 34px;
                    }
                }
            }

            .form {
                font-size: 3rem;

                @include media('<=very-lg') {
                    font-size: 3.8rem;
                }
                @include media('<md') {
                    font-size: 20px;
                }
                @include media('<xs') {
                    font-size: 18px;
                }

                fieldset {
                    margin-top: 3rem;
                    margin-bottom: 0;
                }

                @include media('<=sm') {
                    .field > label {
                        letter-spacing: -1px;
                    }
                }

                .field-services {
                    margin-bottom: 1rem;

                    & > label {
                        margin-bottom: 2.5rem;

                        @include media('<=sm') {
                            margin-bottom: 16px;
                        }
                    }
                }

                .service-checkboxes label {
                    @include media('>xl') {
                        font-size: 0.6em;
                    }
                }

                [type="submit"] {
                    width: 100%;

                    @include media('<=sm') {
                        margin-top: 10px;
                        margin-bottom: 0;
                    }
                }
            }

            .thank-you-content {
                .btn-close {
                    display: none;
                }
            }
        }
    }

    &-bar-mobile {
        @include media('>=md') {
            display: none;
        }

        button {
            width: 100%;
            padding: 12px 0 14px;
            font-size: 20px;
            font-weight: bold;

            @include media('<xs') {
                font-size: 16px;
            }
        }
    }
}



