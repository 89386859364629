.jobs-under-text{
    margin-bottom: 80px;

    @include media('<md') {
        margin-bottom: 8vh;
    }

    @include media('>=md', '<lg') {
        margin-bottom: 40px;
    }

    &__content{
        p{
            margin-top: 40px;
            font-size: 3.3rem;

            @include media('<md') {
                font-size: 16px;
                margin-top: 8px;

            }

            @include media('<=md') {
                font-size: 16px;
            }

            @include media('>md' , '<lg') {
                font-size: 16px;
            }
        }
    }
}
