.about-us-section {
    padding: 6rem 0;

    @include media('<=sm') {
        padding: 35px 0;
    }

    &-number {
        font-size: 12rem;
        line-height: 1;
        font-weight: bold;
        font-family: $font-heading;
        color: $color-cadillac-purple;
        text-align: center;

        .dark & {
            color: $color-white;
        }

        @include media('<=lg') {
            font-size: 64px;
        }

        @include media('<=sm') {
            font-size: 64px;
            text-align: left;
        }
        @include media('<xs') {
            font-size: (64/1.23)*1px;
        }
    }

    &.has-background {
        margin: 8.5rem 0 7rem;
        padding: 13rem 0 17rem;
        position: relative;

        @include media('<=sm') {
            margin: 35px 0;
            padding: 80px 0;
        }

        &.dark {
            color: $color-white;
        }

        .background {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: no-repeat 50% 0 / cover;
            z-index: -1;
        }

        .image {
            margin-top: 17.5rem;
        }
    }

    &.small-margin {
        padding-top: 1.5rem;
    }

    &.no-margin {
        padding-top: 0;
        padding-bottom: 0;
    }

    h2 {
        margin: 1rem 0 0;
        font-size: 6rem;
        letter-spacing: -0.03425em;
        line-height: 1.2;
        font-weight: bold;
        font-family: $font-heading;
        text-align: center;
        @include reset-letter-spacing(6);

        @include media('<=lg') {
            font-size: 28px;
        }
        @include media('<=sm') {
            font-size: 28px;
            text-align: left;
        }
        @include media('<xs') {
            font-size: 22px;
        }

        &.about-us-people-title {
            margin: 0;
            font-size: 9rem;
            text-align: left;

            @include media('<=sm') {
                font-size: 36px;
            }
            @include media('<xs') {
                font-size: 28px;
            }
        }
    }

    .text-content {
        margin-top: 7.5rem;

        @include media('<=sm') {
            margin-top: 23px;
        }

        p {
            font-size: 3rem;
            line-height: 1.6;
            margin-bottom: 1.7em;

            &:last-child {
                margin-bottom: 0;
            }

            @include media('<=lg') {
                font-size: 18px;
            }

            @include media('<=sm') {
                font-size: 18px;
            }
            @include media('<xs') {
                font-size: 15px;
            }
        }
    }

    .image {
        display: block;
        margin: 13rem auto 1rem;
        max-width: 100%;
        text-align: center;

        @include media('<=sm') {
            margin: 50px 0;
        }
    }
}

.about-us-numbers {
    display: flex;
    justify-content: space-between;
    color: $color-white;
    font-family: $font-heading;

    @include media('<=sm') {
        display: block;
    }

    &-section {
        margin: 20rem 0;
        padding: 19.4rem 0;
        background: $color-black no-repeat 50% 50%;
        background-size: cover;

        @include media('<=sm') {
            padding: 25px 0;
            margin: 90px 0;
        }
    }

    &-item {
        flex: 0 0 auto;
        text-align: center;

        @include media('<=sm') {
            padding: 35px 0;
        }
    }

    &-num {
        margin-bottom: 3rem;
        font-size: 13rem;
        font-weight: bold;
        line-height: 0.92;
        letter-spacing: (-4/130)*1em;

        @include media('<=sm') {
            font-size: 80px;
        }
    }

    &-text {
        font-size: 5rem;
        font-weight: bold;

        @include media('<=sm') {
            font-size: 36px;
        }
    }
}
