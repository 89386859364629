.news-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4rem;

    @include media('<md') {
        display: block;
    }

    &-item {
        padding: 0 4rem;

        &-wrap {
            flex: 0 0 50%;
            margin-bottom: 10rem;

            @include media('<md') {
                margin-bottom: 80px;
            }
        }

        .news-meta {
            display: inline-flex;
            margin-bottom: 2rem;
            font-family: $font-heading;
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: (2 / 16) * 1em;
            text-transform: uppercase;
            color: $color-dove-gray;

            @include media('<=lg') {
                margin-bottom: 10px;
                font-size: 14px;
                letter-spacing: 2px;
            }

            span + span {
                &::before {
                    content: '|';
                    padding: 0 1rem;

                    @include media('<=lg') {
                        padding: 0 12px;
                    }
                }
            }
        }

        .news-title {
            margin-bottom: 2rem;
            font-size: 4.4rem;

            @include media('<=lg') {
                margin-bottom: 8px;
                font-size: 24px;
            }
        }

        .news-lead {
            font-size: 2rem;
            line-height: 1.3;

            @include media('<=lg') {
                font-size: 16px;
                line-height: 1.5;
            }
        }

        .news-image {
            display: block;
            margin-bottom: 3rem;

            @include media('<=lg') {
                margin-bottom: 20px;
            }
        }
    }

    &-main {
        margin-bottom: 8rem;
    }

    &-secondary {
        margin-top: 10rem;

        .news-list-item-wrap {
            flex-basis: 33%;

            @include media('<=lg') {
                margin-bottom: 40px;
            }
        }
    }

    &-more {
        display: block;
        margin: 0 auto 10rem;

        @include media('<=lg') {
            margin-top: 60px;
            margin-bottom: 80px;
        }
    }

    &-subtitle {
        @include media('<=lg') {
            font-size: 24px;
            margin-bottom: 45px;
        }
    }

    .path-frontpage & {
        margin-bottom: 10rem;

        @include media('<=sm') {
            margin-bottom: 100px;
        }
    }
}
