.bu-scroll-outer {
    margin: 100px 0;
    background-color: $color-black;

    @include media('height<600px') {
        height: auto !important;
    }
}

.bu-scroll-inner {
    position: sticky;
    top: 0;
    bottom: 0;
    height: 100vh;

    @include media('height<600px') {
        height: auto;
        position: static;
    }

    @include media('<md') {
        overflow-x: hidden;
    }
}

.bu-section {
    height: 100vh;
    visibility: hidden;

    &--active {
        visibility: visible;
    }
}

.bu-section-nav {
    padding-top: 100px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 200;

    @include media('<md') {
        padding-top: 20px;
    }

    @include media('height<600px') {
        position: static;
    }
}

.bu-section-nav-inner {
    display: flex;
}

.bu-section-link {
    flex: 1;
    margin-right: 20px;
    padding-bottom: 20px;
    color: $color-white;
    text-decoration: none;
    border-bottom: 10px solid $color-white;
    transition: 0.2s border-color;

    @include media('<md') {
        border-width: 4px;
        margin: 0 10px 0 0;
    }

    &:last-child {
        margin: 0;
    }

    &:hover,
    &:active,
    &:focus {
        color: $color-white;
    }

    &.bu-precise {
        &:hover,
        &.active-link {
            border-color: #15b767;
        }
    }
    &.bu-agency {
        &:hover,
        &.active-link  {
            border-color: #ff7100;
        }
    }
    &.bu-bots {
        &:hover,
        &.active-link  {
            border-color: #f2b113;
        }
    }
    &.bu-fabrity {
        &:hover,
        &.active-link  {
            border-color: #e83636;
        }
    }
    &.bu-dp {
        &:hover,
        &.active-link  {
            border-color: #ff7319;
        }
    }
    &.bu-oktawave {
        &:hover,
        &.active-link  {
            border-color: #2db5da;
        }
    }

    h3 {
        font-family: $font-heading;
        font-weight: 900;
        font-size: 28px;

        @include media('<xl') {
            font-size: 24px;
        }

        @include media('<very-lg') {
            font-size: 20px;
        }

        @include media('<lg') {
            display: none;
        }
    }

    p {
        font-size: 18px;

        @include media('<xl') {
            font-size: 15px;
        }

        @include media('<very-lg') {
            display: none;
        }
    }
}

.bu-section-wrapper {
    position: relative;
}

.bu-section-inner {
    height: 100%;
    align-content: space-around;
}

.bu-section {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;

    @include media('height<600px') {
        position: static;
    }

    .bu-section-logo-mobile{
        display: none;
    }

    @include media('<md') {
        .bu-section-logo-desktop{
            display: none;
        }
        .bu-section-logo-mobile{
            display: block;
            padding-top: 80px;
        }
        .bu-section-title{
            padding-top: 25px;
        }
    }
}

.bu-section-content {
    //margin: 0;
    padding-bottom: 100px;
    position: relative;

    @include media('height<600px') {
        padding: 20px 0 20px;
    }

    @include media('<md') {
        padding: 0;
    }
}

.bu-section-title {
    padding-top: 240px;
    font-family: $font-heading;
    font-weight: 900;
    color: $color-white;
    font-size: 90px;

    @include media('<xl') {
        font-size: 70px;
    }

    @include media('<very-lg') {
        font-size: 55px;
    }

    @include media('<lg') {
        font-size: 30px;
        padding-top: 90px;
    }
}

.bu-section__btn-wrapper {
    position: absolute;
    bottom: -50px;

    @include media('<md') {
        position: static;
    }
}

.bu-section__btn {
    padding: 40px 0;
    display: inline-block;
    font-size: 24px;
    font-family: $font-heading;
    font-weight: 900;
    color: $color-white;
    text-decoration: underline;

    @include media('<md') {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        padding: 0;
        height: 60px;
        width: 100%;
        color: $color-white;
        background: lightgray;
    }

    &:hover {
        color: $color-white;
        text-decoration: underline;
    }

    .bu-section--precise & {
        @include media('<md') {
            background-color: #15b767;
        }
    }
    .bu-section--agency & {
        @include media('<md') {
            background-color: #ff7100;
        }
    }
    .bu-section--bots & {
        @include media('<md') {
            background-color: #f2b113;
        }
    }
    .bu-section--fabrity & {
        @include media('<md') {
            background-color: #e83636;
        }
    }
    .bu-section--dp & {
        @include media('<md') {
            background-color: #ff7319;
        }
    }
    .bu-section--oktawave & {
        @include media('<md') {
            background-color: #2db5da;
        }
    }
}

.bu-products-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -20px 0 0;
    padding: 0;

    @include media('<md') {
        margin: 0;
        padding-top: 70px;
        display: block;
    }

    li {
        width: 50%;
        padding: 0 40px 0 0;
        font-size: 20px;
        font-family: $font-regular;
        line-height: 1.4;
        list-style: none;
        color: $color-white;

        @include media('<xl') {
            font-size: 16px;
        }

        @include media('<very-lg') {
            font-size: 15px;
        }

        @include media('<md') {
            width: 100%;
            padding: 0;
        }

        &:nth-child(even) {
            padding-right: 0;
            padding-left: 40px;

            @include media('<md') {
                padding: 0;
            }
        }
    }
}

.bu-products-list-inner {
    padding: 10px 0;
    display: block;
    border-bottom: 1px solid $color-white;

    @include media('<md') {
        padding: 5px 0;
    }
}


.tl__title,
.tl__image,
.tl__list,
.tl__url {
    @include media('height<600px') {
        transform: none !important;
        opacity: 1 !important;
    }
}
