@keyframes rotate-scale-down {
    0% {
        transform: scale(1) rotateZ(0);
    }
    15% {
        transform: scale(0.9) rotateZ(30deg);
    }
    30% {
        transform: scale(0.7) rotateZ(80deg);
    }
    50% {
        transform: scale(0.65) rotateZ(180deg);
    }
    70% {
        transform: scale(0.7) rotateZ(280deg);
    }
    85% {
        transform: scale(0.9) rotateZ(330deg);
    }
    100% {
        transform: scale(1) rotateZ(360deg);
    }
}

.lazyload {
    width: 100%;
    overflow: hidden;

    /*.article-image & {
        width: auto;
    }*/

    img {
        width: 100%;

        &[data-src],
        &[data-srcset] {
            display: block;
            min-height: 1px;
            filter: blur(10px);

            &.loaded {
                filter: none;
            }
        }
    }
}

.lazyload,
.form-loading {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background: url('../img/layout/loader.gif') no-repeat 50% 50%;
    }
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        z-index: 9;
        background: $color-white;
        animation: rotate-scale-down 1.95s linear infinite both;
        transform-origin: 50% 50%;
    }
}

.form-loading {
    &::before {
        background: $color-black;
    }

    &::after {
        background: rgba($color-white, 0.7);
    }
}
