.header {
    position: relative;
    padding-top: 12rem;

    @include media('<=lg') {
        padding-top: 120px;
    }
    @include media('<=sm') {
        padding-top: 135px;
    }

    h1 {
        hyphens: none;
    }

    @include media('<=sm') {
        h1 {
            hyphens: manual;
            //white-space: pre-line;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }

    .subtitle {
        margin-top: 3.8rem;
        margin-left: 0.5rem;
        font-size: 3.6rem;
        line-height: 1.35;
        //letter-spacing: -0.0258em;

        @include media('<=lg') {
            font-size: 18px;
            margin-bottom: 70px;
        }
        @include media('>=md','<lg'){
            margin-bottom: 40px;
            font-size: 16px;
        }
        @include media('<=sm') {
            font-size: 18px;
            line-height: 24px;
        }
        @include media('<xs') {
            font-size: 15px;
        }
    }

    &-image,
    &-image img,
    .image,
    .video {
        display: block;
        width: 100%;
        height: auto;
    }

    &-image-job-list {
        margin-bottom: 3rem;


        @include media('<=sm') {
            margin-bottom: 16px;
        }
    }

    .image,
    .video {
        @include media('>sm') {
            margin-top: 16.5rem;
        }
    }

    &.dark {
        color: $color-white;

        .subtitle {
            font-weight: 300;
        }
    }

    &.sm-dark {
        @include media('>sm') {
            color: $color-white;

            .subtitle {
                font-weight: 300;
            }
        }
    }

    &-landing-page,
    &-pillar,
    &-agency,
    &-job-offer,
    &-contact,
    &-about-us-new {
        @include media('>md') { //, 'landscape'
            display: flex;
            flex-direction: column;
            justify-content: center;

            .header-content {
                transform: translateZ(0);
                width: 100%;
                margin-top: 8vh;
                margin-bottom: 8vh;

                @include media('>md' , '<lg') {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__picture-text{
        margin-top: 8vh;

        @include media('<=md') {
            margin-top: 3vh;
        }
        @include media('>md' , '<lg') {
            margin-top: 40px;
        }

        p{
            font-size: 3.3rem;
            line-height: 1.35;

                @include media('<md') {
                padding-left: 30px;
                padding-right: 30px;
                font-size: 16px;
            }
            @include media('<=md') {
                font-size: 16px;
            }
            @include media('>md' , '<lg') {
                font-size: 16px;
            }
        }

    }

    &-frontpage,
    &-contact,
    &-job-offer {
        .background {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: no-repeat 50% 0 / cover;
        }

    }

    &-404 {
        @include media('>sm') {
            height: 100vh;

            .header-content {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                margin-top: 6rem;
            }
        }
    }

    &-frontpage,
    &-landing-page,
    &-pillar,
    &-agency,
    &-about-us-new {
        @include media('>=lg', 'landscape') {
            min-height: 91vh;
        }
    }

    &-article {
        margin: 15.5rem 0 13.5rem;

        @include media('<=sm') {
            margin: 0 0 70px;
        }

        @include media('>sm') {
            text-align: center;
        }

        h1 {
            font-size: 9.5rem;

            @include media('<=lg') {
                font-size: 42px;
            }

            @include media('<=sm') {
                font-size: 42px;
            }
            @include media('<xs') {
                font-size: 34px;
            }
        }
    }

    &-case-list {
        .subtitle {
            margin: 12rem 0 0;
        }
    }

    &-case {
        margin: 0 0 16.5rem;
        padding: 0;
        overflow: hidden;

        @include media('<=sm') {
            padding: 0;
            margin-bottom: 71px;
        }

        @include media('>sm') {
            text-align: center;
        }

        /*&.dark {
            color: $color-white;
        }*/

        .header-content {
            position: absolute;
            top: 32vh;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .subtitle {
            margin-top: 2.4rem;
        }

        .image {
            margin: 0;

            @include media('>sm') {
                position: relative;
                left: 50%;
                width: 115vh;
                transform: translateX(-50%);
            }

            @include media('>md') {
                width: 192vh;
            }
            //margin-top: (-1000 / 1920) * 100vw;
            //padding-top: 100vh;

            img {
                width: 100%;
            }
        }

        .flat-header & {
            .header-content {
                top: 27vh;
            }

            .image {
                position: static;
                width: auto;
                margin-top: (-1000 / 1920) * 100vw;
                padding-top: 100vh;
                transform: translateX(0);
            }
        }

        .very-flat-header & {
            .header-content {
                top: 24vh;
            }
        }

        .super-flat-header & {
            .header-content {
                //position: static;
                //margin-top: 32vh;
                top: 32vh;
            }

            .image {
                margin-top: 0 !important;
                padding-top: 0;
            }
        }

        @include media('<=lg') {
            .header-content {
                //position: static;
                //margin-top: 137px;
                top: 137px;
            }

            .image {
                margin-top: 0 !important;
                padding-top: 0;
            }
        }
    }

    &-project{
        @include media('>md') {
            background-position-y: 100vh;
            background-repeat: no-repeat;
        }
        @include media('<=md') {
            background-position: center;
            .header-content{
                position: static;
                padding-top: 137px;
            }
            .image{
                display: none;
            }
        }
    }

    &-job-offer {
        position: relative;
        margin: 0 0 13.5rem;

        @include media('<=sm') {
            margin: 0 0 70px;
        }

        @include media('>sm') {
            text-align: center;
        }

        @include media('>md') {
            height: 72vh;
        }

        h1 {
            font-size: 9.5rem;

            @include media('<=lg') {
                font-size: 42px;
            }

            @include media('<=sm') {
                font-size: 42px;
            }
            @include media('<xs') {
                font-size: 34px;
            }
        }
    }

    &-about-us {
        margin-bottom: 7rem;
        padding-top: 0;
        text-align: center;
        overflow: hidden;

        .header-content {
            position: absolute;
            top: 33rem;
            left: 0;
            right: 0;
        }

        .video-play {
            margin-top: 12rem;
        }

        @include media('<=md') {
            .subtitle {
                margin-bottom: 0;
            }

            .video-play {
                margin-top: 5rem;
            }
        }

        @include media('<=sm') {
            text-align: left;

            .header-content {
                top: 137px;
            }

            /*.header-content {
                position: static;
                margin: 137px 0 30px;
            }

            .background {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                z-index: -1;
                background-size: cover;
            }*/
        }
    }

    &-about-us-new {
        padding-top: 24rem;
        margin-bottom: 20rem;
        background: $color-black;

        @include media('<=md') {
            padding-top: 100px;
            padding-bottom: 60px;
            margin-bottom: 0;
        }

        .h1 {
            margin-bottom: 15rem;

            @include media('<=sm') {
                margin-bottom: 60px;
            }
        }
    }

    &-agency {
        &-logo {
            margin-bottom: 10rem;

            img {
                width: 35rem;

                @include media('<=sm') {
                    width: 150px;
                }

                @include media('<xs') {
                    width: 120px;
                }
            }
        }
    }

    &-landing-page {
        h1,
        .subtitle {
            letter-spacing: -0.0258em;
        }
    }

    &-frontpage {
        margin-bottom: 14rem;
        padding-bottom: 14rem;

        @include media('<=sm') {
            margin-bottom: 70px;
            padding-top: 100px;

            .btn-contact {
                width: auto;
            }

            .header-content {
                margin-bottom: 45px;
            }
        }

        @include media('>md') {
            .header-content {
                margin-top: 17.5rem;
            }
        }

        .h1 {
            @include media('<=sm') {
                font-size: 38px;
                line-height: 1.1;
            }

            @include media('<xs') {
                font-size: 34px;
            }

            em {
                font-style: normal;
                color: $color-spring-green;
            }
        }

        .subtitle {
            margin-top: 6rem;
            margin-bottom: 24rem;
        }

        &-logo-list {
            display: flex;
            margin: 9rem -3.7rem 0;
            justify-content: flex-start;
            align-items: center;

            @include media('<=sm') {
                flex-wrap: wrap;
                margin: 30px -25px 0;
            }
        }

        &-logo {
            flex: 0 1 (1/6)*100%;

            @include media('<=sm') {
                flex-basis: 50%;
                text-align: center;
            }

            a {
                display: inline-block;
                margin: 1rem 3.7rem;

                @include media('<=sm') {
                    margin: 18px 25px;
                }
            }

            img {
                max-width: 100%;
            }
        }


    }
}

.promo-image {
    margin-bottom: 11rem;

    @include media('<=sm') {
        margin-bottom: 70px;
    }
}
