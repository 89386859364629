.visually-hidden, // override /core/themes/stable/css/system/components/hidden.module.css
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.clearfix::before,
.clearfix::after {
    content: ' ';
    display: table;
}

.clearfix::after {
    clear: both;
}

noscript {
    .noscript {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0 30px;
        background: $color-red;
        z-index: $z-index-noscript;

        p {
            color: $color-white;
            font-size: 14px;
        }
    }
}

.preload * {
    transition: none !important;
}

.section {
    padding-top: 26rem;
}

h1,
h2,
h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    font-weight: inherit;
}

.h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12rem;
    line-height: 1;
    font-family: $font-heading;
    font-weight: bold;
    letter-spacing: (-4 / 120) * 1em;
    @include reset-letter-spacing(12);

    @include media('<=lg') {
        font-size: 42px;
        margin-bottom: 12px;
    }
    @include media('<=sm') {
        font-size: 42px;
        //letter-spacing: -0.8px;
        margin-bottom: 12px;
    }
    @include media('<xs') {
        font-size: 34px;
    }
}

.article-body-text h2,
.h2 {
    margin-top: 0;
    margin-bottom: 0.2em;
    font-family: $font-heading;
    font-weight: bold;
    font-size: 4.8rem;
    line-height: 1.15;
    letter-spacing: (-0.23 / 48) * 1em;
    @include reset-letter-spacing(4.8);

    @include media('<=lg') {
        font-size: 28px;
        margin-bottom: 15px;
    }
    @include media('<=sm') {
        font-size: 28px;
        margin-bottom: 15px;
    }
    @include media('<xs') {
        font-size: 22px;
    }
}

.article-body-text h3,
.h3 {
    margin-top: 0.28em;
    margin-bottom: 0.4em;
    font-family: $font-heading;
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 1;
    letter-spacing: (-1 / 36) * 1em;
    @include reset-letter-spacing(3.6);

    @include media('<=lg') {
        font-size: 22px;
    }
    @include media('<=sm') {
        font-size: 22px;
    }
    @include media('<xs') {
        font-size: 18px;
    }
}

.h4 {
    margin-top: 0;
    margin-bottom: 0.8em;
    font-family: $font-heading;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: (-1 / 26) * 1em;
    @include reset-letter-spacing(2.6);

    @include media('<=lg') {
        font-size: 20px;
    }
    @include media('<=sm') {
        font-size: 20px;
    }
    @include media('<xs') {
        font-size: 16px;
    }
}

a {
    color: inherit;
    text-decoration: none;

    &.link {
        display: inline-block;
    }

    p &,
    &.link {
        //border-bottom: 1px solid;

        &:hover {
            //border: 0;
        }
    }

    &:hover,
    &:active,
    &:focus {
        color: inherit;
    }
}

button {
    color: inherit;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
}

p {
    margin: 0 0 1.4em;
    line-height: 1.5;
    //font-size: 3.6rem;

    @include media('<=lg') {
        font-size: 18px;
        line-height: 1.7;
    }
    @include media('<=sm') {
        font-size: 18px;
        line-height: 1.7;
    }
    @include media('<xs') {
        font-size: 15px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.k2 {
    display: inline-block;
    width: 3.48em;
    height: 1.74em;
    margin-top: -0.2em;
    font-family: $font-icon;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    line-height: 1;
    vertical-align: middle;
    overflow: hidden;

    &::before {
        font-size: 1.74em;
        content: '\e901';
    }
}

.close {
    float: right;
    display: inline-block;
    position: relative;
    padding: 0;
    color: inherit;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    appearance: normal;
    z-index: 1;

    @include media('<=very-lg') {
        font-size: 10px;
    }

    @include media('<=sm') {
        font-size: 7px;
    }

    span {
        position: relative;
        display: block;
        width: 3em;
        height: 3em;
        transition: 0.15s transform ease-in-out;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 0;
            right: 0;
            top: 50%;
            width: 100%;
            border-bottom: 4px solid;
            transform-origin: 50% 50%;

            @include media('<=sm') {
                border-bottom-width: 3px;
            }

            @include media('>fullhd') {
                border-bottom-width: 8px;
            }
        }

        &::before {
            transform: translateY(-2px) rotate(45deg);
        }

        &::after {
            transform: translateY(-2px) rotate(-45deg);
        }
    }

    &:hover span {
        transform: rotate(90deg);
    }
}

.no-scroll {
    width: 100%;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    &.layer-visible {
        height: 100%;
        -webkit-overflow-scrolling: auto;
        overflow: hidden;
    }
}
