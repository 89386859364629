.hamburger {
    float: right;
    display: inline-block;
    position: relative;
    width: 3em;
    height: 3em;
    margin: 1rem 0 0;
    padding: 0;
    background: none;
    border: 0;
    outline: 0;
    color: inherit;
    cursor: pointer;
    appearance: normal;

    @include media('<=very-lg') {
        font-size: 10px;
    }
    @include media('<xs') {
        font-size: 8px;
    }

    span {
        width: 3em;
        top: 0.5em;

        &,
        &::before,
        &::after {
            display: block;
            position: absolute;
            height: 0;
            right: 0;
            border-bottom: 4px solid;
            transition: width 0.2s;

            @include media('>fullhd') {
                border-bottom-width: 0.4em;
            }
            @include media('<xs') {
                border-bottom-width: 3px;
            }
        }

        &::before,
        &::after {
            content: '';
        }

        &::before {
            width: 2em;
            top: 0.8em;
        }

        &::after {
            width: 2.5em;
            top: 1.6em;
        }
    }

    &:hover {
        //span,
        span::before,
        span::after {
            width: 100%;
        }
    }
}
