.carrier-steps{
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 80px;

    @include media('<md') {
        flex-direction: column;
        margin-bottom: 8vh;
    }

    @include media('>=md', '<lg') {
        margin-bottom: 40px;
    }

    @include media('>=lg') {
        padding-left: 0;
    }

    &__headline{
        width: 28%;
        margin-bottom: 4rem;

        @include media('<md') {
            width: 100%;
        }
    }

    &__text{
        max-width: 300px;

        p{
            font-size: 2.4rem;

            @include media('<=md') {
                font-size: 16px;
            }

            @include media('>md' , '<lg') {
                font-size: 16px;
            }
        }
    }

    &__content{
        counter-reset: list;
    }
    &__item{
        position: relative;
        padding-left: 19rem;
        margin-bottom: 3rem;

        @include media('<md') {
           padding-left: 33rem;
        }

        &:nth-child(even){
            margin-left: 120px;

            @include media('<md') {
                margin-left: 55px;
            }
        }

        &:before{
            font-size: 20rem;
            font-weight: 900;
            vertical-align: middle;
            display: inline-block;
            align-items: center;
            justify-content: center;
            line-height: 100%;
            text-align: center;
            /* width: 54px; */
            opacity: .1;
            content: counter(list);
            counter-increment: list;
            position: absolute;
            left: 0;
            top: 50%;
            -ms-transform: translateY(-40%);
            transform: translateY(-40%);
            font-family: "Gilroy","Arial Black",sans-serif;

            @include media('<md') {
               font-size: 90px;
            }
        }
    }
    &__title{

        h3{
            font-size: 3.4rem;
            font-weight: 700;

            @include media('<md') {
                font-size: 18px;
            }
        }
    }
}
