.bu-description {
    margin: 12rem 0;

    @include media('<=sm') {
        margin-top: 90px;
    }

    &-logo {
        margin-bottom: 30px;
    }

    &-title {
        margin-top: 0;
        margin-bottom: 1.33em;
        font-family: $font-heading;
        font-weight: bold;
        font-size: 9rem;
        line-height: 1.15;

        @include media('<=lg') {
            font-size: 42px;
        }
        @include media('<=sm') {
            font-size: 30px;
        }
        @include media('<xs') {
            font-size: 30px;
        }
    }

    &-name {
        display: block;
        position: relative;
        padding-bottom: 100%;

        &-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 7rem 12rem;
        }

        &-logo {
            flex: 1 1 auto;
            display: inline-flex;
            align-items: center;
        }

        &-url {
            display: block;
            flex: 0 0 auto;
            font-family: $font-heading;
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.08;
            color: $color-white;
            text-align: center;

            @include media('<lg') {
                font-size: 16px;
            }

            @include media('<=sm') {
                padding: 16px 0 18px;
                font-size: 20px;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-white;
            }
        }
    }

    &-text {
        margin-bottom: 4rem;

        @include media('<=sm') {
            margin-bottom: 30px;
        }

        &,
        p {
            font-size: 2.4rem;
            line-height: 1.42;

            @include media('<=lg') {
                font-size: 18px;
            }
            @include media('<=sm') {
                font-size: 16px;
            }
        }
    }

    &-services {
        display: flex;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        font-size: 2rem;
        line-height: 1.4;

        @include media('<=lg') {
            font-size: 14px;
        }
        @include media('<=sm') {
            display: block;
            margin-top: 14px;
            font-size: 16px;
        }

        li {
            flex: 0 0 46%;
            padding: 1em 0;
            border-bottom: 1px solid;
        }
    }
}
