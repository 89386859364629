.layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: $color-white;
    color: $color-black;
    font-family: $font-heading;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.2;
    transition: 0.3s transform ease-in-out, 0s visibility 0.3s;
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include media('<=very-lg') {
        font-size: 3.8rem;
    }
    @include media('<md') {
        font-size: 22px;
    }
    @include media('<xs') {
        font-size: 18px;
    }

    &.layer-open {
        transform: translate3d(0, 0, 0);
        transition: 0.3s transform ease-in-out, 0s visibility;
        visibility: visible;
    }

    &-container {
        position: relative;
        height: 100vh;

        @include media('<=sm') {
            min-height: 100vh;
        }
    }

    &-close {
        margin: 1rem 0 0;

        @include media('<=very-lg') {
            font-size: 10px;
        }

        @include media('<=sm') {
            //margin-top: 6px;
            font-size: 7px;
        }

        /*span {
            &::before,
            &::after {
                border-bottom: 4px solid;

                @include media('<=sm') {
                    border-bottom-width: 3px;
                }

                @include media('>fullhd') {
                    border-bottom-width: 8px;
                }
            }

            &::before {
                transform: translateY(-2px) rotate(45deg);
            }

            &::after {
                transform: translateY(-2px) rotate(-45deg);
            }
        }*/
    }
}

.layer-visible {
    &.mobile-layer-visible {
        // position: fixed; // possible fix for #9680

        .layer {
            padding-bottom: 50px;
        }
    }
}
