.case {
    overflow: hidden;

    .article-body-text {
        p:not(.big-text) {
            margin-bottom: 1.5em;
        }

        h2 {
            margin-top: 1.85em;
            line-height: 1;

            @include media('<=sm') {
                margin: 64px 0 23px;
            }

            &:first-child {
                margin-top: 0.2em;
            }
        }
    }

    .article-video {
        margin-top: 16.5rem;
        margin-bottom: 16.5rem;

        @include media('<=sm') {
            margin: 71px 0;
        }
    }

    .article-image {
        margin: 16.5rem (2 / 28) * 100%;

        @include media('<=sm') {
            margin: 71px 30px 67px;
        }
    }

    &-meta {
        margin-top: 15.5rem;
        margin-bottom: 17rem;

        @include media('<=sm') {
            margin-top: 71px;
            margin-bottom: 71px;
        }

        &-list {
            margin: 0;
            font-family: $font-heading;
            font-weight: bold;
            font-size: 3rem;
            line-height: 1.2;

            @include media('<=lg') {
                font-size: 18px;
                line-height: 1.5;
            }

            @include media('<=sm') {
                font-size: 18px;
                line-height: 1.5;
            }
            @include media('<xs') {
                font-size: 15px;
            }

            dd {
                margin: 0;

                @include media('>sm') {
                    margin: 0 0 1.25em;
                    padding-left: (1 / 4) * 100%;
                }
                @include media('>md') {
                    padding-left: (1 / 6) * 100%;
                }
            }

            dt {
                display: block;

                @include media('>sm') {
                    float: left;
                    margin-right: 10px;
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        transition: 0.3s opacity, 0.2s transform;

        &.fade-out {
            opacity: 0;
        }

        .filters-open & {
            transform: translateY(10rem);
        }

        &-logotypes {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 6rem -5rem;
            line-height: 1;

            @include media('<md') {
                margin: 30px -30px;
            }

            &-item {
                flex: 0 0 (1/6)*100%;
                padding: 2.5rem 5rem;
                text-align: center;

                @include media('<md') {
                    flex-basis: (1/3)*100%;
                    padding: 15px 30px;
                }

                img {
                    max-height: (60/170)*8vw;

                    @include media('<md') {
                        max-height: 30px;
                    }
                }
            }
        }

        &-agency {
            .h3 {
                margin-bottom: 6rem;

                @include media('<=sm') {
                    margin-bottom: 40px;
                }

                @include media('<xs') {
                    margin-bottom: 30px;
                }
            }

            .case-list-all {
                margin-bottom: 7rem;

                @include media('<=sm') {
                    margin-bottom: 80px;
                }

                @include media('<xs') {
                    margin-bottom: 60px;
                }
            }
        }
    }

    &-list-all {
        margin: 0.5rem auto 3rem;

        @include media('<=sm') {
            margin-bottom: 80px;
        }

        .is-all + & {
            display: none;
        }
    }

    &-teaser {
        margin-bottom: 10.5rem;
        flex: 0 1 50%;
        line-height: 1;
        visibility: visible;
        opacity: 1;

        @include media('<=sm') {
            flex: auto;
            margin-bottom: 72px;
        }

        &.filter-hidden {
            visibility: hidden;
            opacity: 0;
            margin: 0;
            width: 0;
            height: 0;
            flex: 0 0 0;
            overflow: hidden;
        }

        .image {
            display: block;
            overflow: hidden;

            picture {
                display: block;
                transition: transform 0.4s ease;
            }

            &:hover picture {
                transform: scale(1.15);
            }
        }

        .client,
        .bu {
            margin: 1.2em 0 0.15em;
            font-size: 3rem;

            @include media('<=sm') {
                font-size: 18px;
            }
            @include media('<xs') {
                font-size: 15px;
            }
        }

        .bu {
            margin: 0.5em 0 0;
        }

        .title {
            display: inline-block;
            padding-right: 1 / 12 * 100%;
            font-family: $font-heading;
            font-weight: bold;
            font-size: 4.8rem;
            letter-spacing: -0.0313em;
            line-height: 1.15;
            @include reset-letter-spacing(4.8);

            @include media('<=sm') {
                padding-right: 0;
                font-size: 28px;
            }
            @include media('<xs') {
                font-size: 22px;
            }
        }
    }

    + .footer {
        margin-top: 0;
    }

    &.alternative-layout {
        .article-body-text {
            margin-bottom: 9.6rem;
        }

        .article-image {
            margin: 13.5rem (4 / 28) * 100% 12rem;
        }

        .article-video {
            margin: 13.5rem (4 / 28) * 100%;
        }
    }
}
