.mega-menu {
    background: $color-black;
    color: $color-white;
    visibility: visible;

    .logo-wrapper {
        width: 175px;
        top: 15.5rem;
    }

    .logo-svg {
        filter: invert(1);
        width: 175px;
    }

    &-container {
        overflow: hidden;
    }

    .menu-open & {
        transform: translateY(0);
    }

    &-close {
        position: relative;
    }

    a {
        color: $color-white;
        transition: color 0.3s ease-in-out;
        /*&:hover,
        &:active,
        &:focus {
            color: $color-silver-gray;
        }*/
    }

    .secondary-nav {
        margin: 8.6rem 0 0;
        padding: 0;
        list-style: none;

        @include media('<=lg') {
            margin-top: 8rem;
        }
        @include media('<=md', 'portrait') {
            margin-top: 13.5rem;
        }
        @include media('<=sm') {
            margin: 35px 0;
        }
        @include media('<=md', 'landscape') {
            margin-top: 30px;
            font-size: 18px;
        }
        @include media('<=sm', 'landscape') {
            margin-left: 75px;
        }

        li {
            display: inline-block;
            margin-right: 1rem;

            @include media('<=sm', 'portrait') {
                display: block;
            }
        }

        a {
            display: inline-block;
            padding: 1.6rem 0;

            @include media('<=lg') {
                padding: 2.7rem 0;
            }
            @include media('<=sm') {
                padding: 7px 0;
            }
        }
    }

    .main-nav {
        ul {
            margin-bottom: 20px;

            @include media('<=md', 'landscape') {
                margin-top: 30px;
            }

            @include media('<=sm') {
                text-align: center;
            }

            @include media('<=sm', 'landscape') {
                display: flex;
                justify-content: space-between;
                margin: 0;
            }

            a {
                @include media('<=md') {
                    margin-bottom: 0.85em;
                }
                @include media('<=sm') {
                    font-size: 24px;
                }
                @include media('<xs') {
                    font-size: 22px;
                }
                @include media('<=sm', 'landscape') {
                    margin-bottom: 0;
                }
            }
        }

        a {
            color: $color-white;
            /*&:hover,
            &:active,
            &:focus {
                color: $color-silver-gray;
            }*/
        }

        //@include media('<=sm', 'portrait') {
        @include media('<=md') {
            position: static;
            transform: translateY(0);
            margin-top: 110px;
            margin-bottom: 85px;
            font-size: 16px;
        }

        @include media('<=sm', 'landscape') {
            margin-bottom: 40px;
        }

        @include media('<xs') {
            font-size: (16 / 1.23) * 1px;
        }

        @include media('height<570px') {
            margin-top: 90px;
            margin-bottom: 35px;
        }

        @include media('height<270px', 'landscape') {
            margin-top: 80px;
            margin-bottom: 20px;
        }
    }

    .blog-promo {
        position: absolute;
        bottom: 8.6rem;
    }
}
