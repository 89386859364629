@font-face {
    font-family: 'icons';
    src: url('#{$font-path}/icons.ttf?c45p4h') format('truetype'),
    url('#{$font-path}/icons.woff?c45p4h') format('woff'),
    url('#{$font-path}/icons.svg?c45p4h#icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@mixin icon-font {
    /* use !important to prevent issues with browser extensions that change fonts */

    /* stylelint-disable */
    font-family: 'icons' !important;
    /* stylelint-enable */
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin icon($icon) {
    content: $icon;
    @include icon-font;
}

$ico-contact: "\e902";
$ico-checked: "\e903";
$ico-plus: "\e904";
$ico-arrow-down: "\2193";
$ico-arrow-right: "\2192";
$ico-arrow-left: "\2190";
$ico-chevron-right: "\e900";
$ico-logo: "\e901";

.ico {
    @include icon-font;
}

.ico-contact {
    &:before {
        content: $ico-contact;
    }
}
.ico-checked {
    &:before {
        content: $ico-checked;
    }
}
.ico-plus {
    &:before {
        content: $ico-plus;
    }
}
.ico-arrow-down {
    &:before {
        content: $ico-arrow-down;
    }
}
.ico-arrow-right {
    &:before {
        content: $ico-arrow-right;
    }
}
.ico-arrow-left {
    &:before {
        content: $ico-arrow-left;
    }
}
.ico-chevron-right {
    &:before {
        content: $ico-chevron-right;
    }
}
.ico-logo {
    &:before {
        content: $ico-logo;
    }
}
