.slider {
    position: relative;
    margin-bottom: 10.3rem;

    @include media('<=sm') {
        margin-bottom: 70px;
    }

    &.no-animation * {
        transition: none !important;
    }

    &-category {
        position: absolute;
        z-index: 1;
        top: 10.5%;
        left: (2 / 24) * 100%;
        color: $color-white;
        font-family: $font-heading;
        font-size: 3.6rem;
        font-weight: bold;
        letter-spacing: -0.033em;
        transition: opacity 300ms;
        @include reset-letter-spacing(3.6);

        @include media('<=sm') {
            top: (30 / 600) * 100%;
            font-size: 22px;
        }

        &:active,
        &:focus,
        &:hover {
            color: $color-white;
        }

        &-white {
            opacity: 1;
            pointer-events: auto;

            .slider-black-text & {
                opacity: 0;
                pointer-events: none;
            }
        }

        &-black {
            color: $color-black;
            opacity: 0;
            pointer-events: none;

            &:active,
            &:focus,
            &:hover {
                color: $color-black;
            }

            .slider-black-text & {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .slide {
        display: block;
        position: relative;

        @include media('<=sm') {
            &.show-overlay::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                background: rgba($color-black, 0.2);
            }
        }

        &-content {
            position: absolute;
            color: $color-white;
            z-index: 2;

            @include media('<=sm') {
                left: 30px;
                right: 30px;
                bottom: 60px;
            }

            @include media('>sm') {
                top: 50%;
                left: (2 / 24) * 100%;
                width: (8 / 24) * 100%;
                transform: translateY(-50%);
            }
        }

        .title {
            //margin-top: 6.1rem;
            margin-top: 3rem;
            margin-bottom: 3.7rem;
            font-family: $font-heading;
            font-size: 9.5rem;
            font-weight: bold;
            line-height: 1;
            letter-spacing: -0.033em;
            hyphens: none;
            @include reset-letter-spacing(9.5);

            @include media('<=sm') {
                font-size: 42px;
                margin-top: 20px;
                //letter-spacing: -0.7px;
                margin-bottom: 17px;
                hyphens: manual;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
            @include media('<xs') {
                font-size: 34px;
            }
        }

        .subtitle {
            font-size: 3.6rem;
            line-height: 1.25;
            font-weight: 300;

            @include media('<=sm') {
                font-size: 22px;
            }
            @include media('<xs') {
                font-size: 18px;
            }
        }

        .bu {
            font-family: $font-heading;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.15em;

            @include media('<=very-lg') {
                font-size: 12px;
            }

            @include media('<=lg') {
                font-size: 11px;
            }

            @include media('<=sm') {
                font-size: 14px;
            }
        }

        .image {
            display: block;
            width: 100%;
            height: auto;
            /*transform-origin: 50% 50%;
            transform: scale(1.4);
            transition: transform 1s $slide-easing;*/

            @include media('<=md', 'landscape') {
                max-height: 100rem;
            }

            picture,
            img {
                max-width: none;
                width: 100%;
            }
        }

        &-black {
            .slide-content {
                color: $color-black;
            }
        }
    }

    &.slider-blog {
        .slide-content {
            @include media('>sm') {
                width: (18 / 24) * 100%;
            }
        }

        .title {
            @include media('>sm') {
                margin-bottom: 4.3rem;
            }
        }

        .meta {
            font-size: 3rem;
            line-height: 1.25;
            font-weight: 300;

            @include media('<=sm') {
                font-size: 22px;
            }
            @include media('<xs') {
                font-size: 18px;
            }
        }
    }

    &.slider-loaded {
        $slide-easing: cubic-bezier(0.13, 0.72, 0.2, 1);
        $slide-easing-a: cubic-bezier(0.165, 0.84, 0.44, 1);

        .slide {
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            transform: translateX(100%);
            transition: transform 1s $slide-easing;

            &:first-child {
                position: relative;
            }

            &-active {
                transform: translateX(0%);
                /*.image {
                    transform: scale(1);
                }*/
            }
        }
    }

    &-wrap {
        position: relative;
        overflow: hidden;
    }

    &-arrow {
        position: absolute;
        top: 50%;
        display: block;
        padding: 1.5rem;
        border: 0;
        outline: 0;
        border-radius: 50%;
        transform: translateY(-50%);
        font-size: 3rem;
        color: $color-dusty-gray;
        background: none;
        cursor: pointer;
        z-index: 1;

        @include media('<md') {
            display: none !important;
        }

        &::before {
            position: relative;
            display: inline-block;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
            z-index: 1;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 4rem;
            height: 17.4rem;
            top: -5.7rem;
        }

        &:hover {
            color: $color-black;
        }

        &-next {
            right: -3rem;

            &::after {
                right: 3rem;
                margin-right: -1px;
                background: url('../img/layout/slider-arrow-bg.png') no-repeat 100% 50% / contain;
            }

            &:hover {
                &::before {
                    transform: translateX(5px);
                }
            }

        }

        &-prev {
            left: -3rem;

            &::after {
                left: 3rem;
                margin-left: -1px;
                background: url('../img/layout/slider-arrow-bg.png') no-repeat 0 50% / contain;
                transform: rotate(180deg);
            }

            &:hover {
                &::before {
                    transform: translateX(-5px);
                }
            }

        }
    }

    &-dots {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;

        @include media('>md') {
            display: none;
        }

        li {
            display: inline-block;
        }

        button {
            display: inline-block;
            width: 9px;
            height: 9px;
            padding: 0;
            margin: 15px 5px;
            border: 0;
            outline: 0;
            border-radius: 50%;
            background: $color-alto-gray;
            transition: background-color 0.3s ease-in-out;
            text-indent: -999px;
            overflow: hidden;
        }

        .slick-active button {
            background: $color-black;
        }
    }
}

.slick-list {
    .slick-loading & {
        background: none;
    }
}
