.filter {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;

    select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
    }

    &-title {
        display: inline-block;
        margin-right: 2.8rem;
        vertical-align: middle;
    }

    &-label {
        position: relative;
        display: inline-block;
        cursor: pointer;

        &::after {
            content: '.';
        }
    }

    &-title,
    &-label {
        letter-spacing: -0.03em;
    }

    &-list {
        position: absolute;
        vertical-align: top;

        ul {
            margin: 3.6rem 0 0;
            padding: 0;
            list-style: none;
            font-size: 2.4rem;
            line-height: 1.6;
            letter-spacing: -0.024em;

            .item {
                padding-right: 5px;
                cursor: pointer;
            }

            .active {
                color: $color-silver-chalice-gray;
            }

            ul {
                margin-top: 0;
                display: inline-block;

                li {
                    display: inline-block;
                    padding-right: 2px;

                    &::before {
                        content: '\2014\a0';
                    }
                }

                .item {
                    padding-left: 5px;
                }
            }
        }

        .no-parent {
            li {
                &:first-child {
                    &::before {
                        display: none;
                    }

                    .item {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    &-list-items {
        overflow: hidden;
        max-height: 0;
        transition: 0.2s max-height;

        &.open {
            max-height: 30rem;
        }
    }
}
