.body-text {
    margin: 3rem 0 13rem 0.2em;
    font-size: 3.6rem;

    @include media('<=sm') {
        margin-top: 0;
        margin-bottom: 40px;
    }
}

.side-links {
    margin-top: 3.4rem;
    font-family: $font-heading;
    font-size: 3.6rem;
    font-weight: bold;

    @include media('<=lg') {
        font-size: 22px;
    }

    @include media('<=sm') {
        margin-top: 0;
        margin-bottom: 70px;
        font-size: 22px;
    }
    @include media('<xs') {
        font-size: 18px;
    }

    ul {
        margin: 5.2rem 0 0;
        padding: 0;
        list-style: none;

        @include media('<=sm') {
            margin-top: 15px;
        }
    }

    .link {
        margin-bottom: 0.2em;
        line-height: 1;
    }
}

.text-section {
    &-title {
        margin-top: 0;

        @include media('<=lg') {
            font-size: 24px;
        }
        @include media('<=md') {
            margin-bottom: 30px;
        }
    }

    p {
        font-size: 2.4rem;
        line-height: 1.5;

        @include media('<=lg') {
            font-size: 16px;
        }
        @include media('<sm') {
            font-size: 16px;
        }
    }

    .lead {
        font-family: $font-heading;
        font-weight: bold;
        font-size: 5rem;
        line-height: 1.2;

        @include media('<=lg') {
            font-size: 24px;
        }
        @include media('<=sm') {
            margin-bottom: 20px;
            font-size: 24px;
        }
        @include media('<xs') {
            font-size: 22px;
        }
    }
}
