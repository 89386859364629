.newsletter {
    &-teaser {
        margin: 3rem 0 15rem;
        text-align: center;
        font-size: 3.6rem;
        line-height: 1.333;

        @include media('<=sm') {
            margin-top: 0;
            margin-bottom: 80px;
            font-size: 18px;
            line-height: 1.44;
            text-align: left;
        }
        @include media('<xs') {
            font-size: 15px;
        }
    }

    &-bar {
        padding-top: 23px;
        padding-bottom: 22px;

        @include media('<=md') {
            padding-top: 15px;
            padding-bottom: 14px;

            &-content {
                padding-right: 25px;
            }
        }

        .close {
            position: absolute;
            top: 50%;
            right: (2 / 28) * 100%;
            padding: 1rem;
            transform: translateY(-50%);
            font-size: 5px;

            @include media('<=sm') {
                right: 30px;
            }

            span {
                &::before,
                &::after {
                    border-bottom: 2px solid;
                }

                &::before {
                    transform: translateY(-1px) rotate(45deg);
                }

                &::after {
                    transform: translateY(-1px) rotate(-45deg);
                }
            }
        }
    }
}
