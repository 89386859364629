.accordion {

    margin-bottom: 80px;

    @include media('<md') {
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 8vh !important;
    }

    @include media('>=md', '<lg') {
        margin-bottom: 40px;
    }

    @include media('>=lg') {
        padding-left: 0;
    }

    &__subtitle{
        font-size: 2.8rem;
        margin-bottom: 4rem;

        @include media('<md') {
            font-size: 18px;
        }
    }

    @include media('<md') {
        margin-top: 30px;
        margin-bottom: 30px;

        .main-column &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }

    @include media('>=md') {
        &:first-child {
            margin-top: 0;
        }
    }

    &__title {
        margin-top: 40px;
    }

    &-trigger {
        display: block;
        width: 100%;
        position: relative;
        padding: 14px 50px 14px 2px;
        line-height: 1.33;
        text-align: left;
        transition: color 0.7s ease-in-out;

        @include media('<md') {
            padding: 17px 40px 12px 0;


        }

        @include plus;

        &::before,
        &::after {
            top: 30px;

            @include media('<md') {
                top: 50%;
            }
        }

    }

    .accordion-body{

        &__text{
            font-size: 2.8rem;
        }
    }

    .accordion-item{
        width: 100%;
        border-bottom: 1px solid #8e8b8b;
        min-height: 50px;
    }

    &-body {
        position: relative;
        //padding: 0 10px;
        padding: 0;
        overflow: hidden;
        transition: height .5s ease-in-out;

        @include media('<md') {
            //padding-right: 30px;
            //padding-left: 30px;
        }

        &[aria-hidden="true"] {
            height: 0 !important;
        }

        &-inner {
            padding: 25px 0;
        }

        .text {
            max-width: 990px;
        }
    }

    .accordion-trigger{
        font-size: 3.5rem;
        font-weight: 900;
        font-family: Gilroy,"Arial Black",sans-serif;

        @include media('<md') {
            font-size: 18px;
        }
    }
    @include media('>=md') {
        .hero-title + &,
        .hero + & { // jesli akordeon jest zaraz po hero, to nie ma kreski i odstepu
            margin-top: -50px;

            .accordion-item:first-child .accordion-trigger {
                border-top: 0;
            }
        }
    }

    @include media('<md') {
        .hero-title + & {
            margin-top: 0;
        }

        .hero + & {
            margin-top: -30px;

            .accordion-item:first-child .accordion-trigger {
                border-top: 0;
            }
        }
    }
}

