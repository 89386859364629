.form {
    width: 100%;
    font-family: $font-heading;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.2;

    @include media('<=very-lg') {
        font-size: 3.8rem;
    }
    @include media('<md') {
        font-size: 22px;
    }
    @include media('<xs') {
        font-size: 18px;
    }

    &-title {
        margin-bottom: 5rem;

        @include media('<md') {
            margin-top: 75px;
            margin-bottom: 55px;
        }

        h2 {
            margin-bottom: 1rem;
            font-size: 6rem;
            letter-spacing: -0.03em;
            @include reset-letter-spacing(6);

            @include media('<=lg') {
                font-size: 42px;
            }
            @include media('<=sm') {
                font-size: 42px;
            }
            @include media('<xs') {
                font-size: 34px;
            }
        }
    }

    fieldset {
        display: block;
        width: 100%;
        margin: 5rem 0;
        padding: 0;
        border: 0;

        @include media('<=lg') {
            margin: 45px 0;
        }

        @include media('<=sm') {
            margin: 55px 0;
        }
    }

    .field {
        margin-bottom: 2rem;

        @include media('<=lg') {
            margin-bottom: 20px;
        }
        /*@include media('<=sm') {
            margin-bottom: 10px;
        }*/

        &.error {
            [type="text"],
            [type="tel"],
            [type="email"],
            [type="password"],
            textarea {
                margin-bottom: 2rem;
                border-color: $color-sunset-orange;

                @include media('<=sm') {
                    margin-bottom: 13px;
                }
            }

            .radio,
            .checkbox {
                label::before {
                    border-color: $color-sunset-orange;
                }
            }

            .error-message {
                display: block;
            }
        }
    }

    [type="text"],
    [type="tel"],
    [type="email"],
    [type="password"],
    textarea {
        display: block;
        width: 100%;
        padding: 2rem 0;
        border: 0;
        border-bottom: 1px solid $color-black;
        border-radius: 0;

        @include media('<=sm') {
            padding: 13px 0;
        }

        &::placeholder {
            opacity: 1;
        }

        &:focus {
            outline: 0;
        }
    }

    textarea {
        height: 8em;
        resize: none;
    }

    label {
        display: block;
        margin: 1rem 0 3rem;

        @include media('<=lg') {
            margin: 0 0 20px;
        }

        @include media('<=sm') {
            margin: 0 0 30px;
        }
    }

    .radio,
    .checkbox {
        position: relative;
        padding-left: 5rem;
        margin-bottom: 1.4rem;
        font-family: $font-regular;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.25;

        @include media('<=lg') {
            font-size: 14px;
            padding-left: 25px;
            margin-bottom: 20px;
        }

        @include media('<=sm') {
            font-size: 18px;
            padding-left: 45px;
            margin-bottom: 20px;
        }
        @include media('<xs') {
            font-size: 15px;
        }

        label {
            margin-bottom: 0;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                font-size: 2.5rem;
                width: 1em;
                height: 1em;
                border: 2px solid $color-black;
                transition: border-width 0.3s ease-in-out;

                @include media('<=lg') {
                    font-size: 15px;
                }
                @include media('<=sm') {
                    font-size: 27px;
                }
                @include media('<xs') {
                    font-size: (27 / 1.23) * 1px;
                }
            }
        }

        [type="radio"],
        [type="checkbox"] {
            display: none;

            &:checked + label::before {
                border-width: 0.36em;
            }
        }

        &.error {
            label::before {
                border-color: $color-sunset-orange;
            }
        }
    }

    .radio {
        label::before {
            border-radius: 50%;
        }
    }

    .more,
    .show-less {
        display: none;
    }

    .more-visible {
        .more,
        .show-less {
            display: inline;
        }

        .show-more {
            display: none;
        }
    }

    .service-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -1rem -1rem 1rem;

        @include media('<md') {
            margin: 0 0 20px;
        }

        .service-checkbox {
            flex: 0 0 auto;
            padding: 1rem;

            @include media('<md') {
                flex-basis: 100%;
                padding: 6px 0;
            }
        }

        label {
            margin: 0;
            padding: 1.3rem 1.8rem 1rem 1.1rem;
            background: $color-white;
            border: 2px solid $color-black;
            font-family: $font-regular;
            font-size: (18/24)*1em;
            font-weight: 500;
            line-height: 1.44;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            transition-property: background-color, color;

            @include media('<md') {
                padding: 8px 8px 4px;
                font-size: 14px;
            }

            &::before {
                @include icon($ico-plus);
                display: inline-block;
                margin-right: 0.5em;
                font-size: (20/18)*1em;
                vertical-align: baseline;
            }

            &:hover,
            &:active,
            &:focus {
                background: $color-black;
                color: $color-white;
            }
        }

        [type="checkbox"] {
            display: none;

            &:checked + label {
                background: $color-black;
                color: $color-white;

                &::before {
                    @include icon($ico-checked);
                }
            }
        }
    }

    .field-services {
        > label {
            margin-bottom: 2rem;

            @include media('<md') {
                margin-bottom: 20px;
            }
        }
    }

    [type="submit"] {
        margin-top: 2rem;
        cursor: pointer;

        @include media('<=sm') {
            margin-top: 50px;
        }
    }

    .form-type-webform-markup {
        font-weight: 500;
        opacity: 0.3;
        a {
            text-decoration: underline;
        }
    }
    .error-message {
        display: none;
        color: $color-sunset-orange;
        font-size: 1.8rem;

        @include media('<=lg') {
            font-size: 12px;
        }

        @include media('<=sm') {
            font-size: 16px;
        }
        @include media('<xs') {
            font-size: (16 / 1.23) * 1px;
        }
    }

    .global-errors {
        .error-message {
            display: block;
        }
    }

    &-protected-pdf{
        margin-bottom: 2em;
        label {
            margin: 2rem 0 1rem;
        }
    }

    &-pdf {
        .h2 {
            margin-bottom: 1.8em;
        }

        fieldset {
            margin: 5.8rem 0;
        }

        .field {
            margin-bottom: 1.5rem;

            @include media('<=lg') {
                margin-bottom: 20px;
            }
        }

        [type="text"],
        [type="tel"],
        [type="email"],
        textarea {
            padding: 1.8rem 0;

            @include media('<=sm') {
                padding: 13px 0;
            }
        }

        .protected-form-title {
            margin-top: 1em;
            margin-bottom: 1em;
        }

        .protected-form-description {
            font-family: $font-regular;
            font-weight: normal;
        }

        .protected-form-global-required{
            margin-top: -20px;
            margin-bottom: 20px;
        }

    }
}

.thank-you-content {
    display: none;

    .form-title {
        margin-bottom: 1.5rem;
    }

    p {
        font-size: 3rem;
        font-family: $font-regular;
        font-weight: normal;
        color: $color-gray;

        @include media('<=lg') {
            font-size: 16px;
        }

        @include media('<=sm') {
            font-size: 16px;
        }
        @include media('<xs') {
            font-size: (16 / 1.23) * 1px;
        }
    }

    .btn {
        margin-top: 6rem;
    }
}

.thank-you {
    .form-content, .protected-form-description {
        display: none;
    }

    .thank-you-content {
        display: block;
        font-weight: normal;
        font-family: $font-regular;
        a{
            text-decoration: underline;
        }
    }
}
