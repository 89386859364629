$font-path: '../fonts';

/*@font-face {
    font-family: 'maitree';
    src: url('#{$font-path}/maitree-bold.woff2') format('woff2'),
    url('#{$font-path}/maitree-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'maitree';
    src: url('#{$font-path}/maitree-regular.woff2') format('woff2'),
    url('#{$font-path}/maitree-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: url('#{$font-path}/maitree-v2-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: local('Maitree Light'), local('Maitree-Light'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/maitree-v2-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/maitree-v2-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/maitree-v2-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/maitree-v2-latin_latin-ext-300.svg#Maitree') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url('#{$font-path}/maitree-v2-latin_latin-ext-regular.eot');
    src: local('Maitree'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-regular.woff2') format('woff2'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-regular.woff') format('woff'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-regular.ttf') format('truetype'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-regular.svg#Maitree') format('svg');
}

@font-face {
    font-family: 'Maitree';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url('#{$font-path}/maitree-v2-latin_latin-ext-700.eot');
    src: local('Maitree Bold'), local('Maitree-Bold'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-700.woff2') format('woff2'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-700.woff') format('woff'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-700.ttf') format('truetype'),
    url('#{$font-path}/maitree-v2-latin_latin-ext-700.svg#Maitree') format('svg');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('#{$font-path}/35C199_0_0.eot');
    src: url('#{$font-path}/35C199_0_0.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/35C199_0_0.woff2') format('woff2'),
    url('#{$font-path}/35C199_0_0.woff') format('woff'),
    url('#{$font-path}/35C199_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url('#{$font-path}/35C199_1_0.eot');
    src: url('#{$font-path}/35C199_1_0.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/35C199_1_0.woff2') format('woff2'),
    url('#{$font-path}/35C199_1_0.woff') format('woff'),
    url('#{$font-path}/35C199_1_0.ttf') format('truetype');
}
