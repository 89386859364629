*,
*::before,
*::after {
    box-sizing: border-box;
}

::selection {
    background-color: $color-mine-shaft-gray;
    color: $color-white;
}

a:hover,
a:focus,
a:active,
input:focus {
    outline: none;
    color: inherit;
    text-decoration: none;
}

img {
    display: inline-block;
    max-width: 100%;
    vertical-align: top;
    user-select: none;
}

html {
    font-size: 10px;
}

html,
body {
    height: 100%;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: $font-regular;
    //padding-top: 12rem;
    transition: opacity 0.2s ease-in-out;
    color: $color-black;

    &.preload {
        opacity: 0;
    }

    /*&.path-frontpage {
        padding-top: 0;
    }*/
}

picture {
    img {
        width: 100%;
        max-width: none;
    }
}

.page-wrap {
    height: 100%;
    font-size: 1.8rem;
    transition: 0.3s transform ease-in-out;
    //transform: translateY(0px);

    .menu-open & {
        transform: translateY(100%);
    }
}

@include media('<=sm') {
    .row {
        padding-left: 30px;
        padding-right: 30px;
    }
    .row.fullbleed {
        padding-left: 0;
        padding-right: 0;
    }
}
