.reward-badge {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 10rem;
    height: 10rem;
    text-indent: -666em;
    overflow: hidden;
    z-index: 999;
    background-repeat: no-repeat;
    background-size: contain;

    &-effie {
        width: 14rem;
        height: 14rem;
        margin-right: 3rem;
        background-image: url('../img/content/ribbons/effie.svg');

        @include media('<lg') {
            width: 80px;
            height: 80px;
        }
    }
}
