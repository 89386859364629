.popup {
    .layer-close {
        position: absolute;
        top: $top-desktop;
        right: (2 / 28) * 100%;
        margin-top: 6.6rem;
        font-size: 10px;
        -webkit-tap-highlight-color: transparent;

        @include media('>sm') {
            margin-right: -1em;
        }

        @include media('<=md') {
            top: $top-tablet;
            margin-top: 0;
        }

        @include media('<=sm') {
            top: $top-phone;
            right: 30px;
        }
    }

    &-content-centered {
        @include media('>=md') {
            //position: absolute;
            //top: 50%;
            //transform: translateY(-50%);
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            //justify-content: center;
        }

        @include media('>md', 'landscape') {
            padding: 50px 0;
        }
    }

    &.dark {
        background: $color-black;
        color: $color-white;
    }

    .video {
        position: relative;
        width: 100%;
        max-height: 100%;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
    }
    .video-sizer {
        display: block;
        height: auto;
        width: 100%;
        max-height: 100vh;
    }
    iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }
}
.no-scroll .tippy-popper {
    position: fixed !important;
}
