.footer {
    background: $color-black;
    color: $color-white;
    font-size: 2.4rem;
    margin-top: 9rem;

    .path-frontpage & {
        margin-top: 0;
    }

    @include media('<=lg') {
        font-size: 12px;

        .contact-heading {
            font-size: 22px;
        }
    }

    @include media('<=sm') {
        margin-top: 0;
        font-size: 18px;

        .contact-heading {
            font-size: 28px;
        }
    }

    @include media('<xs') {
        font-size: 15px;

        .contact-heading {
            font-size: 22px;
        }
    }

    + .footer {
        margin-top: 0;
    }

    .contact-name {
        margin-bottom: 2rem;
    }

    .contact-heading {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    .social-contact {
        margin: 10px 0 0;
        padding: 0;
        list-style: none;
        font-size: 1.8rem;
        font-weight: bold;
        li {
            padding: 5px 0;
        }
    }
    .social-links {
        margin: 10px 0 0;
        padding: 0;
        list-style: none;
        font-size: 1.8rem;
        font-weight: bold;
        li {
            padding: 5px 0;
        }
    }
    a {
        color: $color-white;
    }

    p {
        margin: 0 4rem 0 0;
        line-height: 1.35;

        @include media('>sm') {
            font-size: 2rem;
        }
    }

    @include media('<=sm') {
        .contact-image {
            margin-top: 70px;
            margin-bottom: 30px;
        }
    }

    &-contact {
        padding-top: 15rem;
        padding-bottom: 15rem;
        border-bottom: 1px solid $color-mine-shaft-gray;

        @include media('<=sm') {
            font-size: 18px;
        }

        @include media('<xs') {
            font-size: 15px;
        }

        &-heading {
            margin-bottom: auto;
        }
    }

    &-group {
        padding-top: 9.5rem;
        padding-bottom: 5rem;

        @include media('<=sm') {
            padding-top: 50px;
            padding-bottom: 30px;
        }

        &-row {
            display: flex;
            flex-wrap: wrap;

            @include media('<=sm') {
                display: block;
            }

            @include media('>sm') {
                & + & {
                    margin-top: 6rem;
                }
            }
        }

        &-item {
            flex: 0 0 25%;
            margin: 5rem 0 6.5rem;
            font-size: 1.8rem;
            line-height: 1.44;

            @include media('<=lg') {
                flex-basis: 50%;
                font-size: 12px;
            }

            @include media('<=sm') {
                margin: 30px 0 0;
                font-size: 16px;
            }
        }
    }

    &-links {
        padding-top: 11rem;
        padding-bottom: 10rem;
        font-size: 1.8rem;
        font-family: $font-heading;
        font-weight: bold;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        @include media('<=sm') {
            font-size: 14px;

            &-col {
                display: inline-block;
                width: 47%;

                &:first-child {
                    margin-right: 6%;
                }
            }

            /*.first-list {
                margin-bottom: 70px;
            }*/
        }

        li {
            margin-bottom: 1.8rem;
        }
    }

    @include media('<=lg', '>sm') {
        &-contact,
        &-links,
        p {
            font-size: 14px;
        }

        .h3 {
            font-size: 16px;
        }

        .h4 {
            font-size: 14px;
        }

        .contact-heading {
            font-size: 22px;
        }
    }
    @include media('<=sm') {
        &-contact,
        &-links,
        p {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        p {
            font-size: 16px;
        }
    }
}
