.people {
    margin-top: 18rem;
    margin-bottom: 20rem;

    h2 {
        font-size: 9.5rem;
        margin-bottom: 1.25em;

        @include media('<=lg') {
            font-size: 42px;
            margin-bottom: 70px;
        }
        @include media('<=sm') {
            font-size: 42px;
            margin-bottom: 70px;
        }
        @include media('<xs') {
            font-size: 34px;
        }
    }

    .person {
        margin-bottom: 10rem;
        font-size: 3.6rem;

        @include media('<=lg') {
            font-size: 28px;
            margin-bottom: 70px;
        }
        @include media('<=sm') {
            font-size: 28px;
            margin-bottom: 70px;
        }
        @include media('<xs') {
            font-size: 22px;
        }

        img {
            width: 100%;
            max-width: none;
        }
    }

    .name {
        margin: 1.4em 0 0.1em;
        font-family: $font-heading;
        font-weight: bold;

        @include media('<=sm') {
            margin: 20px 0 0;
        }
    }

    .position {
        margin-bottom: 1.5em;
        font-size: 0.66em;
        color: $color-gray;

        @include media('<=sm') {
            margin-bottom: 18px;
        }
    }

    .description {
        font-family: $font-heading;
        line-height: 1.47;

        @include media('<=sm') {
            font-size: 22px;
        }
        @include media('<xs') {
            font-size: 18px;
        }
    }
}
