.login-form {
    width: 100%;

    @include media('<=md') {
        .form-title {
            margin-top: 85px;
        }
    }

    .form-item {
        margin-bottom: 30px;
    }

    label {
        margin: 0;
        display: block;
    }

    [type="text"],
    [type="password"] {
        &.error {
            border-color: $color-sunset-orange;
        }
    }

    .description {
        margin: 0.5rem 0;
        font-size: 16px;
        font-weight: normal;
        color: $color-gray;
    }

    .form-item--error-message {
        font-size: 16px;
        font-weight: bold;
        color: $color-sunset-orange;
    }

    .tabs,
    .messages.messages--error {
        display: none;
    }
}

.path-user {
    .page-wrap {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        justify-content: center;
    }
}
