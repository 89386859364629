.main-wrap {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
}

$anim-speed: 60ms;
//$anim-bezier: cubic-bezier(0.165, 0.84, 0.44, 1);
$anim-bezier: cubic-bezier(0.13, 0.72, 0.2, 1);

.on-top {
    //touch-action: none;

}

.main-slider {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 3.6rem;
    line-height: 1.2;
    touch-action: none;

    @include media('<=md') {
        font-size: 16px;
    }
    @include media('<=md', 'portrait') {
        font-size: 9rem;
    }
    @include media('<=sm', 'portrait') {
        font-size: 19px;
    }
    @include media('<xs') {
        font-size: 16px;
    }

    &-section {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate3d(0, 150%, 0);
        will-change: transform;
        backface-visibility: hidden;
    }

    &-slide {
        &--active {
            transform: translate3d(0, 0, 0) !important;
            transition: transform 10 * $anim-speed $anim-bezier !important;

            .main-slider-story-bg {
                transform: translate3d(0, 0, 0) !important;
                //transition: transform 10 * $anim-speed $anim-bezier;
            }
            .main-slider-indicator {
                pointer-events: none;
                opacity: 0;
                transform: translate3d(0, -50%, 0);
                transition: transform 4 * $anim-speed, opacity 3 * $anim-speed;
            }
        }
        &--current {
            + .main-slider-section {
                transform: translate3d(0, 100%, 0);
                transition: transform 5 * $anim-speed 7 * $anim-speed $anim-bezier;
            }

            &:first-child {
                + .main-slider-section {
                    transform: translate3d(0, 91%, 0);

                    &:hover {
                        .main-slider-inner {
                            transform: translate3d(0, 1%, 0);
                        }
                    }

                    .main-slider-story-bg {
                        transform: translateY(-45%);
                        right: (1 / 14) * 100%;
                        left: (1 / 14) * 100%;

                        @include media('<=sm') {
                            right: 30px;
                            left: 30px;
                        }
                        @include media('<=md') {
                            transform: translateY(-35%);
                        }
                    }
                }
            }
        }
        &--released {
            transition: transform 12 * $anim-speed $anim-bezier !important;
        }

        &.dark + .main-slider-section {
            .main-slider-indicator:hover {
                .main-slider-indicator-arrow {
                    color: $color-white !important;
                }
            }
        }
    }
    &-story {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        transition: transform 3 * $anim-speed;
        will-change: transform;

        &-bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: no-repeat 50% 50% / cover;
            transform: translate3d(0, -20%, 0);
            transition: 10 * $anim-speed $anim-bezier;
            transition-property: transform, left, right;
            will-change: transform;
        }
    }
    &-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 9%;
        width: 2em;
        margin: auto;
        opacity: 1;
        transform: translate3d(0, -100%, 0);
        transition: transform 7 * $anim-speed 6 * $anim-speed ease-out, opacity 4 * $anim-speed 4 * $anim-speed;
        will-change: transform, opacity;

        @include media('<sm') {
            top: -2vh;
            width: 100px;
            height: 60px;
        }

        /*&-image {
            display: block;
            height: 9rem;
            width: 100%;
            background: no-repeat 50% 33% / cover;
            transform: scale(1.2);
            transition: transform 3 * $anim-speed, opacity 3 * $anim-speed;

            @include media('<lg') {
                height: 15rem;
            }
            @include media('<sm') {
                height: 32rem;
                margin-top: -40px;
            }
        }*/
        &-arrow {
            position: absolute;
            //top: size(-13);
            //bottom: 4rem;
            top: 2rem;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 50%;
            transition: transform 3 * $anim-speed, color 0.3s;
            color: $color-white;
            text-align: center;

            @include media('<very-lg') {
                font-size: 4.5rem;
            }

            @include media('<lg') {
                bottom: 16rem;
                font-size: 6rem;
            }

            @include media('<md') {
                font-size: 21px;
            }

            @include media('<sm') {
                top: 0;
                bottom: 0;
                height: 60px;
                line-height: 60px;
            }
        }
        &:hover {
            .main-slider-indicator-image {
                transform: translate3d(0, size(15), 0) scale(1.2);
            }
            .main-slider-indicator-arrow {
                transform: translate3d(0, -15%, 0);
                color: $color-black !important;
            }
        }
    }

    &-inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: transform 3 * $anim-speed;
        will-change: transform;
    }

    .main-slider-slide:first-child {
        + .main-slider-slide {
            .main-slider-indicator {
                width: (12 / 14) * 100%;
                transform: translate3d(0, -2%, 0);
                background: url('../img/layout/blob.svg') no-repeat 50% 0;

                @include media('<md') {
                    width: 100%;
                    background-size: 30vh;
                }
                @include media('<md', 'portrait') {
                    background-size: 27vw;
                }

                @include media('<sm') {
                    top: 0;
                    height: 9vh;
                }
            }

            .main-slider-indicator-arrow {
                top: -2rem;
                color: $color-dusty-gray;

                @include media('<sm') {
                    top: auto;
                    bottom: 9vh;
                    margin-bottom: -27px;
                }
            }
        }
    }

    &-content-wrap {
        position: relative;
        height: 100%;
    }

    &-content {
        position: absolute;
        z-index: 1;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);

        @include media('<=lg') {
            bottom: 12vh;
            top: auto;
        }

        @include media('>lg') {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .title {
        margin-bottom: 3.7rem;
        font-size: 9.5rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -2 / 95 * 1em;
        font-family: $font-heading;
        color: $color-white;
        @include reset-letter-spacing(9.5);

        a {
            color: $color-white;
        }

        @include media('<=md') {
            font-size: 16rem;
        }
        @include media('<=sm') {
            font-size: 42px;
        }
        @include media('<xs') {
            font-size: 34px;
        }
    }

    .subtitle {
        color: $color-white;
        font-weight: 300;

        @include media('<=sm') {
            font-size: 22px;
        }
        @include media('<xs') {
            font-size: 18px;
        }
    }
}

.main-slider-active {
    .page-wrap {
        touch-action: none;
    }

    .main-wrap {
        pointer-events: all;
        z-index: 0;
    }
    .main-content {
        min-height: 100vh;
        transform: translate3d(0, 100vh, 0);

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            // IE 10+
            transform: translate3d(0, 100%, 0);
        }
    }
}

.non-scrollable {
    .page-wrap {
        overflow-y: hidden;
    }
}

.path-frontpage {
    overscroll-behavior-y: none;

    &.is-first-slide-active {
        overscroll-behavior-y: auto;
    }
}

.main-content {
    background: $color-white;
    transform: translate3d(0, 0, 0);
    transition: transform 10 * $anim-speed 3 * $anim-speed $anim-bezier;
    will-change: transform;

    @include media('<=sm') {
        padding-top: 93px;
    }

    @include media('>sm') {
        .path-frontpage & {
            padding-bottom: 9rem;
        }
    }

    .section {
        margin-bottom: 20px;
        transform: translate3d(0, 0, 0) scale(1);
        transition: transform 10 * $anim-speed 3 * $anim-speed $anim-bezier;
        will-change: transform;
    }
}
