#cookiesjsr {
  --default-margin: 1.25em;
  --font-size-reset: 1rem;
  --btn-font-color: #000;
  --btn-border-color: #000;
  --btn-bg-color: #fff;
  --btn-prime-font-color: #fff;
  --btn-prime-border-color: #000;
  --btn-prime-bg-color: #000;
  --btn-inv-font-color: #fff;
  --btn-inv-border-color: #fff;
  --btn-inv-bg-color: #000;
  --btn-prime-inv-font-color: #000;
  --btn-prime-inv-border-color: #fff;
  --btn-prime-inv-bg-color: #fff;
  --link-list-font-color: #000;
  --link-list-separator-color: #000;
  --banner-logo-offset: 100px;
  --banner-bg-color: #fff;
  --banner-font-color: #000;
  --layer-header-height: 3.5em;
  --layer-header-bg-color: #fff;
  --layer-header-font-color: #000f37;
  --layer-body-bg-color: #fff;
  --layer-tab-bg-color: #fff;
  --layer-tab-font-color: #000;
  --layer-tab-active-bg-color: #000;
  --layer-tab-active-font-color: #fff;
  --layer-bg-dark: #fff;
  --layer-font-light: #fff;
  --layer-font-dark: #000;
  --layer-border-color: #e4e5e6;
  --layer-footer-bg-color: #fff;
  --layer-footer-font-color: #000;
  --layer-footer-height: 4.5em;
  --switch-border-color: #e4e5e6;
  --switch-handle-color: #fff;
  --switch-bg-off: #fff;
  --switch-bg-on: #00ee85;
  --switch-width: 45px;
  --switch-height: 20px;
  --switch-always-on-font-color: #00ee85;
  --switch-always-on-bg-color: #fff

}



//banner
.cookiesjsr-banner {
  width: auto;

  &--info {
    width: 65%;
  }

  &--action {
    width: 35%;
  }
}

.cookiesjsr-banner--text {
  font-size: 2rem;
  line-height: 1.25;
}

.cookiesjsr-links.links--row li a {
  font-size: 2rem;
  text-decoration: underline;
}

.cookiesjsr-btn {
  font-family: "Gilroy ExtraBold";
  font-size: 2.4rem;
  text-transform: none;

  &:hover {
    transform: none;
  }

  &.allowAll:hover {
    background: #fff;
    color: #000;
  }
}

//modal

.cookiesjsr-layer--header {
  text-align: center;
}

.cookiesjsr-layer--title {
  font-family: "Gilroy Regular";
  font-size: 1.6rem;
  text-transform: none;
  width: 100%;
}

.cookiesjsr-service-group--tab {
  font-family: "Gilroy Regular";
  font-weight: 400;
  font-size: 1.8rem;
  width: 30%;
}

.cookiesjsr-service-group {
  &.active {
    background: #000;
    color: #fff;
  }
}

.cookiesjsr-service-group--intro {
  font-size: 2rem;
  line-height: 1.25;
}

.cookiesjsr-service--always-on span {
  background: none;
}

.cookiesjsr-layer--footer {
  overflow: hidden;

  .cookiesjsr-btn {
    font-size: 1.6rem;
    width: 30%;
  }
  .cookiesjsr-layer--actions {
    justify-content: end;
    flex-direction: row-reverse;
  }

}

.cookiesjsr-layer--label-all {
  font-family: "Gilroy Regular";
  font-size: 2rem;
}

.cookiesjsr-service--description {
  padding: 0.625em 1.25em;
  width: calc(100% - 2.5em - 45px);
  font-size: 2rem;
}

@include media('<md') {
  .cookiesjsr-banner--info {
    width: 100%;
  }
  .cookiesjsr-banner--action {
    flex-direction: row;
    width: 100%;
  }
  .cookiesjsr-banner--text {
    font-size: 12px;
  }
  .cookiesjsr-btn {
    font-size: 12px;
  }

  .cookiesjsr-links.links--row li a {
    font-size: 12px;
  }

  .cookiesjsr-layer  {
    font-size: 12px;
  }
  .cookiesjsr-layer--title {
    font-size: 12px;
  }

  .cookiesjsr-service-group--tab, .cookiesjsr-service-group--intro, .cookiesjsr-service--description h3,.cookiesjsr-layer--footer .cookiesjsr-btn {
    font-size: 12px;
  }

  .cookiesjsr-layer button.allowAll, .cookiesjsr-layer button.denyAll {
    display: block;
    width: 40%;
  }
}
