.header-highlight {
    &-wrapper {
        position: relative;
        margin-bottom: -2rem;
        overflow: hidden;
        transform: translateZ(0);
    }
    &-background {
        position: relative;
        padding-bottom: 2rem;
        background-color: $color-white;
        mix-blend-mode: lighten;
        z-index: 2;
    }
    &-bulb {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        border-radius: 50%;
        opacity: 0;
        z-index: 1;
        background-color: red;
        transition: opacity 900ms;

        &.moved {
            opacity: 0.9;
        }
        &-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            animation: bulb-animation 5000ms infinite ease both;
        }
    }
    .h1 {
        cursor: default;
    }
}

@keyframes bulb-animation {
    0%, 100% {
        transform: scale3d(0.9, 0.9, 0.9);
        opacity: 1;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1.1);
        opacity: 0.8;
    }
}
