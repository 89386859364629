.contact-bubble {
    position: fixed;
    bottom: 1.4em;
    right: 1.4em;
    width: 3.9em;
    height: 3.9em;
    line-height: 1;
    border-radius: 50%;
    font-size: 2.1rem;
    color: $color-black;
    background: $color-concrete-gray;
    -webkit-tap-highlight-color: transparent;
    transform: scale(0);
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, transform;

    @include media('<=lg') {
        font-size: 13px;
    }

    .ico {
        display: inline-block;
        vertical-align: middle;
    }

    &:hover {
        background-color: $color-black;
        color: $color-concrete-gray;
    }

    &.bar-in {
        transform: scale(1);
    }
}
