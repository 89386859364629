.video {
    position: relative;

    &-wrap {
        position: relative;
        padding-bottom: (9 / 16) * 100%;
        height: 0;
    }

    &-thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: no-repeat 50% 50% / cover;

        .playing & {
            display: none;
        }
    }

    &-play {
        display: inline-block;
        width: 19.4rem;
        height: 19.4rem;
        background: url('../img/layout/video-play.png') no-repeat 50% 50% / contain;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        outline: 0;
        font-size: 0;

        @include media('<=md') {
            width: 65px;
            height: 65px;
        }

        @include media('<=sm') {
            width: 60px;
            height: 60px;
        }

        &:hover {
            background-image: url('../img/layout/video-play-hover.png');
        }

        .video & {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
