.person-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;

    @include media('<=sm') {
        margin: 30px 0 60px;
    }

    .contact-person {
        flex: 0 1 auto;

        @include media('<=sm') {
            margin: 73px 0;
        }

        @include media('>sm') {
            flex: 0 1 25%;
            margin-top: 11.9rem;
            margin-right: 5%;
            font-size: 2.35rem;
        }

        .title {
            margin-top: 3.5rem;

            @include media('<=md') {
                margin: 22px 0 14px;
            }
            @include media('<=sm') {
                margin: 22px 0 14px;
            }
        }

        p {
            margin-bottom: 0;
            line-height: 1.56;
        }


        &-about {
            @include media('<=sm') {
                margin: 30px 0;
            }

            @include media('>md') {
                flex: 0 1 30%;
                margin: 4rem 5% 4rem 0;

                &:nth-child(3n+3) {
                    margin-right: 0;
                }
            }

            @include media('>sm', '<=md') {
                flex: 0 1 45%;
                margin: 4rem 10% 4rem 0;

                &:nth-child(2n+2) {
                    margin-right: 0;
                }
            }

            .title {
                margin-bottom: 0.1em;
            }
        }
    }
}
