@function size($size) {
    @return unquote(($size / 1920 * 100) + 'vw');
}

@mixin reset-letter-spacing($font-size-rem) {
    $breakpoint: 1920 * 36 / $font-size-rem * 0.1px;
    @include media ('<=' + $breakpoint) {
        letter-spacing: 0;
    }
}
@mixin plus($width: 21px) {
    &::before,
    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        width: $width;
        height: (3/21) * $width;
        border-radius: 0 2px;
        background: $color-black;
        transform: translateY(-50%);
        transition: 0.2s ease-in-out;
        transition-property: transform, background-color;

        @include media('<md') {
            right: 0;
            width: (18/21) * $width;
            height: (2/21) * $width;
        }
    }

    &::after {
        transform: translateY(-50%) rotate(90deg);
    }

    &:hover,
    &:active,
    &:focus {
        &::before,
        &::after {
            background-color: $color-black;
        }
    }

    &[aria-expanded="true"] {
        &::before {
            transform: translateY(-50%) rotate(180deg);
        }

        &::after {
            transform: translateY(-50%) rotate(360deg);
        }
    }
}
@mixin btn-colors($foreground, $background, $hoverForeground: $background, $hoverBackground: $foreground) {
    background: $background;
    color: $foreground;

    &:hover,
    &:active,
    &:focus {
        background: $hoverBackground;
        color: $hoverForeground;
    }

    @media (hover: none) {
        &:hover,
        &:active,
        &:focus {
            background: $background;
            color: $foreground;
        }
    }
}
