.main-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .slogan {
        color: $color-gray;
        margin-bottom: 1.2em;

        @include media('<=md', 'height<360px') {
            margin-top: 1em;
            margin-bottom: 0.5em;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        /*li {
            position: relative;
            z-index: 1;
            &:hover {
                z-index: 2;
            }
        }*/

        a {
            display: inline-block;
            margin-left: -7 / 12 * 1rem;
            color: $color-black;
            text-decoration: none;
            font-family: $font-heading;
            font-weight: bold;
            font-size: 12rem;
            letter-spacing: (-4 / 12) * 1rem;
            line-height: 1;
            transition: color 0.3s ease-in-out;
            @include reset-letter-spacing(12);

            @include media('<=md') {
                margin-bottom: 0.35em;
            }
            @include media('<=md', 'portrait') {
                font-size: 17rem;
                margin-bottom: 0.5em;
            }
            @include media('<=md', 'height<360px') {
                margin-bottom: 0.15em;
            }
            @include media('<=sm') {
                font-size: 42px;
            }
            @include media('<xs') {
                font-size: 34px;
            }
        }
    }
}
