.btn,
[type="submit"] {
    display: inline-block;
    margin: 2rem 0;
    padding: 2.1rem 6rem;
    border: 2px solid $color-black;
    font-size: 2.4rem;
    font-family: $font-heading;
    font-weight: bold;
    transition: 0.3s;
    transition-property: background, color;

    @include btn-colors($color-white, $color-black);

    @include media('<=lg') {
        margin: 50px 0;
        font-size: 22px;
    }

    @include media('<=sm') {
        display: block;
        width: 100%;
        margin: 50px 0;
        padding: 20px;
        font-size: 22px;
        text-align: center;
    }
    @include media('<xs') {
        font-size: 18px;
    }

    &.btn-transparent {
        @include btn-colors($color-black, transparent, $color-white, $color-black);
    }

    &.btn-white-transparent {
        border-color: $color-white;

        @include btn-colors($color-white, transparent, $color-black, $color-white);
    }

    &.btn-white {
        border-color: $color-white;

        @include btn-colors($color-black, $color-white, $color-white, $color-black);
    }

    &.btn-contact {
        margin: 0;
        padding: 0.5em 1em;
        font-size: 2rem;

        @include media('<=very-lg') {
            font-size: 2.5rem;
        }

        @include media('<=lg') {
            font-size: 3rem;
        }

        @include media('<sm') {
            padding: 11px 26px;
            margin-left: auto;
            margin-right: auto;
            font-size: 20px;
            text-align: center;
        }
        @include media('<xs') {
            font-size: 18px;
        }
    }

}
