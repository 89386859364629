.landing-page {
    &-teaser {
        margin-top: 9.5rem;
        font-weight: bold;
        font-size: 3.6rem;
        font-family: $font-heading;
        text-align: center;
        letter-spacing: -0.0258em;

        .btn {
            margin: 4rem 0 3rem;

            @include media('<=sm') {
                margin: 70px 0;
            }
        }
    }

    &-boxes {
        margin-bottom: 9rem;
    }

    &-box {
        margin-bottom: 50px;

        @include media('>sm') {
            margin-bottom: 7rem;

            &-content {
                .inner {
                    height: 100%;
                    padding: 12rem (2 / 12) * 100% 5rem;
                    background: $color-seashell-gray;
                }
            }

            .landing-page-box-image {
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 4rem;
                    height: 17.4rem;
                    top: 50%;
                    transform: translateY(-50%);
                    background: no-repeat 50% 50% / contain;
                }
            }

            &:nth-child(2n+1) {
                .landing-page-box-image {
                    margin-left: (1 / 14) * 100%;

                    &::after {
                        right: 0;
                        background-image: url('../img/layout/lp-img-bg-left.png');
                    }
                }
            }
            &:nth-child(2n) {
                .landing-page-box-content {
                    margin-left: (1 / 14) * 100%;
                }
                .landing-page-box-image {
                    order: 1;

                    &::after {
                        left: 0;
                        background-image: url('../img/layout/lp-img-bg-right.png');
                    }
                }
            }
        }

        h2 {
            margin-bottom: 3rem;
            font-family: $font-heading;
            font-size: 4.8rem;
            font-weight: bold;
            line-height: 1.125;

            @include media('<=sm') {
                margin: 10px 0;
                font-size: 26px;
            }
        }

        p {
            $max-lines: 5;

            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            margin-bottom: 1em;
            font-size: 3rem;
            line-height: 1.333;

            @include media('>sm') {
                max-height: ($max-lines * 1.333) * 1em;
                overflow: hidden;
                -webkit-line-clamp: $max-lines;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

            @include media('<=md') {
                margin-bottom: 0.5em;
                font-size: 14px;
            }

            @include media('<=sm') {
                font-size: 18px;
            }
        }

        .more {
            font-size: 2.4rem;
            line-height: 1.666;

            @include media('<=md') {
                font-size: 14px;
            }

            @include media('<=sm') {
                font-size: 18px;
            }
        }
    }
}
