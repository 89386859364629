.fancy-hover,
.fancy-underline {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    &-em {
        font-style: inherit;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        &::selection {
            text-shadow: none !important;
        }
    }
}

.absolute-width {
    position: absolute !important;
    top: -99999px !important;
    left: -99999px !important;
    width: 9999px !important;
    max-width: none !important;
    br {
        display: none;
    }
}
