.about {
    position: relative;
    padding-top: 26rem;
    padding-bottom: 2rem;
    margin-bottom: 18rem;
    background: $color-black;
    color: $color-white;

    @include media('<=sm') {
        padding-top: 120px;
        margin-bottom: 75px;
    }
    @include media('<xs') {
        padding-top: 80px;
    }

    + .footer,
    + .newsletter-bar-wrap + .footer {
        margin-top: 0;
        border-top: 1px solid $color-mine-shaft-gray;
    }

    h2 {
        margin: 0 0 2.2em -0.1em;
        font-size: 9.5rem;
        line-height: 1;
        letter-spacing: -0.03em;
        @include reset-letter-spacing(9.5);

        @include media('<=lg') {
            font-size: 42px;
        }
        @include media('<=sm') {
            font-size: 42px;
        }
        @include media('<xs') {
            font-size: 34px;
        }
    }

    &-agency {
        padding-top: 17rem;
        margin-bottom: 10.5rem;

        @include media('<=sm') {
            padding-top: 60px;
            margin-bottom: 40px;
        }

        @include media('<xs') {
            margin-bottom: 30px;
        }

        h2 {
            margin-bottom: 12rem;

            @include media('<=sm') {
                margin-bottom: 40px;
            }

            @include media('<xs') {
                margin-bottom: 30px;
            }
        }
    }

    .service {
        padding-bottom: 15rem;
        font-size: 3.6rem;
        font-family: $font-heading;
        line-height: 1.5;
        letter-spacing: -0.03em;
        @include reset-letter-spacing(3.6);

        @include media('<=lg') {
            font-size: 22px;
            line-height: 1.27;
            letter-spacing: 0;
        }
        @include media('<=sm') {
            padding-bottom: 50px;
            font-size: 22px;
            line-height: 1.27;
            letter-spacing: 0;
        }
        @include media('<xs') {
            font-size: 18px;
        }

        &-title {
            font-weight: bold;

            @include media('<=sm') {
                margin-bottom: 15px;
                font-size: 28px;
                line-height: 1.25;
            }
            @include media('<xs') {
                font-size: 22px;
            }
        }

        &-description {
            p {
                margin-bottom: 0.6em;

                @include media('<=sm') {
                    font-size: 22px;
                }
            }

            a {
                color: $color-white;
            }

            small {
                display: inline-block;
                line-height: 1.7;
                letter-spacing: 0;
                color: $color-gray;

                @include media('>md') {
                    font-size: 0.66em;
                }

                a {
                    color: $color-gray;
                }
            }

            .btn {
                margin-top: 5rem;
                padding-left: 5em;
                padding-right: 5em;

                @include media('<=sm') {
                    margin-top: 60px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }

    @keyframes marquee {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes marquee-left {
        0% {
            left: 0;
        }
        100% {
            left: -50%;
        }
    }

    .marquee {
        display: block;
        position: absolute;
        top: 5.3rem;
        width: 100%;
        overflow: hidden;
        font-family: $font-heading;
        font-weight: bold;
        font-size: 30rem;
        line-height: 1.2;
        letter-spacing: -0.03em;
        color: $color-cod-gray;
        white-space: nowrap;
        user-select: none;
        @include reset-letter-spacing(30);

        @include media('<=lg') {
            top: 28px;
            font-size: 116px;
        }
        @include media('<=sm') {
            top: 58px;
            font-size: 110px;
        }
        @include media('<xs') {
            top: 22px;
            font-size: 90px;
        }

        &-inner {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
        }

        span {
            position: relative;
            display: inline-block;
            top: 0;
            left: 0;
            animation: marquee 15s linear infinite;

            @include media('<=sm') {
                animation: marquee-left 15s linear infinite;
            }
        }
    }

    &.services {
        padding-top: 11rem;
        padding-bottom: 6rem;
        margin-bottom: 0;

        @include media('<=sm') {
            padding-top: 60px;
        }
        @include media('<xs') {
            padding-top: 40px;
        }

        h2 {
            margin-top: 4.3rem;
            margin-bottom: 1.3em;
        }

        .service {
            padding-bottom: 10.5rem;

            @include media('<=sm') {
                padding-bottom: 50px;
            }
        }
    }
}
