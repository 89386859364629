$color-white: #fff;
$color-seashell-gray: #f1f1f1;
$color-concrete-gray: #f3f3f3;
$color-alto-gray: #d8d8d8;
$color-silver-gray: #bebebe;
$color-silver-chalice-gray: #ababab;
$color-dusty-gray: #999;
$color-gray: #8d8d8d;
$color-dove-gray: #676767;
$color-mine-shaft-gray: #333333;
$color-cod-gray: #191919;
$color-black: #000;
$color-sunset-orange: #ff4c37;
$color-red: #f00;
$color-cadillac-purple: #a54c72;
$color-spring-green: #21f18b;

$z-index-noscript: 1000;

$font-heading: 'Gilroy', 'Arial Black', sans-serif;
$font-regular: 'Maitree', Georgia, serif;
$font-icon: 'icons' !important;
