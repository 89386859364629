.job-list-item,
.filtered-list-item {
    overflow: hidden;
    /*transition: visibility 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    max-height 0s 0.3s ease-in-out;*/

    @include media('<=sm') {
        padding-top: 30px;
    }

    .meta {
        font-size: 2.4rem;
        color: $color-gray;
        margin-bottom: 9.55rem;

        @include media('<=lg') {
            font-size: 18px;
            line-height: 1.5;
        }
        @include media('<=sm') {
            margin-bottom: 20px;
            font-size: 18px;

            .author {
                display: block;
            }
        }
        @include media('<xs') {
            font-size: 15px;
        }

        .link {
            color: $color-gray;
        }

        .job-list & {
            color: $color-black;
        }
    }

    &.filter-hidden {
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        padding: 0;
    }
}

.job-list {
    &-header {
        @include media('<=sm') {
            margin-bottom: 24px;
        }
        @include media('>md') {
            font-size: 3.4rem;
        }
    }

    &-item {
        .h2 {
            margin-bottom: 0.1em;

            @include media('<=sm') {
                margin-bottom: 6px;
            }
        }
    }
}

.filtered-list-empty {
    margin-top: 1.5rem;
    transition: 0.3s opacity;

    @include media('<=sm') {
        margin-bottom: 43px;
    }

    p {
        font-size: 3.5rem;
        margin-bottom: 9.55rem;
        line-height: 1.3;

        @include media('<=lg') {
            font-size: 18px;
            line-height: 1.5;
        }
        @include media('<=sm') {
            margin-bottom: 20px;
            font-size: 18px;

            .author {
                display: block;
            }
        }
        @include media('<xs') {
            font-size: 15px;
        }
    }
}

.filtered-list-items {
    margin-top: 13.5rem;
    transition: 0.3s opacity;

    @include media('<md') {
        margin-bottom: 16px;
    }

    &.fade-out {
        opacity: 0;
    }
}

.filter-categories {
    // fix skakania contentu na hoverze
    transform: translateZ(0);

    @include media('<=lg') {
        font-size: 18px;
    }
    @include media('<xs') {
        font-size: 15px;
    }

    @include media('>md') {
        position: sticky;
        top: 16rem;
    }

    ul {
        margin: 10.5rem 0 0;
        padding: 0;
        list-style: none;
        font-family: $font-heading;
        font-weight: bold;
        font-size: 3.4rem;
    }

    li {
        margin-bottom: 0.3em;

        a {
            color: $color-silver-gray;

            &::after {
                content: '\e900';
                display: inline-block;
                font-family: $font-icon;
                font-size: 0.75em;
                margin-left: 0.6em;
                opacity: 0;
                transform: translateX(-1rem);
                transition: 0.2s ease-in-out;
                transition-property: opacity, transform;
            }

            &.active::after,
            &:hover::after {
                opacity: 1;
                transform: translateX(0);
            }
        }

        .active {
            color: $color-black;
        }

        /*span {
            font-weight: normal;
        }*/
    }

    &-mobile {
        display: inline-flex;
        margin-top: 8px;

        .filter {
            margin-left: 10px;
        }
    }
}

.path-frontpage .filtered-list {
    margin-top: 10rem;
    margin-bottom: 10rem;

    @include media('<=sm') {
        margin-top: 20px;
        margin-bottom: 0;
    }
}
