.bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: $color-black;
    color: $color-white;
    text-align: center;
    font-size: 2rem;
    font-family: $font-heading;
    transform: translateY(100%);
    transition: transform 0.5s;

    @include media('<=very-lg') {
        font-size: 2.8rem;
    }

    @include media('<=md') {
        font-size: 14px;
        line-height: 1.44;
        //text-align: left;
    }

    &.bar-in:not(.bar-unpinned) {
        transform: translateY(0);
    }
}
